import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DateRangePicker } from "react-date-range";
import { makeStyles } from "@material-ui/core/styles";
import { DataGrid, frFR } from "@material-ui/data-grid";
import { Colors } from "../../theme";
import {
  Container,
  Typography,
  Card,
  CardContent,
  TextField,
  Grid,
  Button,
  InputAdornment,
  Popover,
  Slider,
  CircularProgress,
  Snackbar,
  Backdrop,
  Box,
  Switch,
} from "@material-ui/core";
import { Autocomplete, Alert, createFilterOptions } from "@material-ui/lab";
import { css } from "aphrodite";
import { AppStyles } from "../../theme";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import moment from "moment";
import frLocale from "date-fns/locale/fr";
import DateFnsUtils from "@date-io/date-fns";
import {
  ACTIVITIES_LISTING,
  ACTIVITY_SCENARIO,
  ACTIVITY_TYPE,
  GOALS,
  THEMES,
  PARTICIPANT_TYPES,
  GET_SERVICE_POINT_YOUTH_CENTER,
  ACTIVITY_CHARACTERISTICS,
  UPDATE_USER_YOUTH_CENTER,
  GET_REGIONS,
  ETIQUETTES_LISTING,
  GLOBAL_ETIQUETTES_LISTING,
} from "../../graphql";
import { UPDATE_USER } from "../../actions/ActionTypes";
import { checkPermissions } from "../../helpers/utils";

import xlsxTemplate from "../../assets/xlsx_templates/activities.xlsx";
import xlsxTemplateRMJQ from "../../assets/xlsx_templates/activitiesRMJQ.xlsx";

import {
  DATE_FORMAT11,
  TIME_FORMAT1,
  ROUTES,
  USER_PERMISSIONS,
} from "../../constants";
import styles from "./ActivitiesListingStyles";
import { Header } from "../../components";
import _, { forEach, set } from "lodash";
import { useHistory } from "react-router-dom";
import { CalendarToday, GetApp, Search } from "@material-ui/icons";
import * as XLSX from "xlsx";
import { getSortedOptions } from "../../helpers/dataHelpers";
import { fr } from "date-fns/locale";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
moment.locale("fr");

const getParticipantAge = (p, reportDate) => {
  if (p.year) {
    const age = moment(reportDate).diff(
      `${p.year}-${p.month || "01"}-${p.day || "01"}`,
      "years"
    );
    return age;
  } else {
    return 0;
  }
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(2),
  },
  flexClass: { flex: 1 },
  appBarBg: {
    background: Colors.brand.primary,
  },
  inputFieldsWrapper: {
    marginTop: theme.spacing(2),
  },
  hrMargin: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  hrColor: {
    backgroundColor: "rgba(0, 0, 0, 0.2)",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },

  green: {
    color: Colors.brand.primary,
  },
  formControl: {
    minWidth: "100%",
  },
  deleteBtn: {
    background: Colors.brand.primary,
    color: Colors.white,
    border: `1px solid ${Colors.brand.primary}`,
    textTransform: "uppercase",
    fontWeight: 400,
    boxShadow: "2px 2px 8px 1px #0000002b",
    "&:hover": {
      color: Colors.brand.primary,
      background: Colors.white,
      boxShadow: "none",
    },
  },
  cardWrapper: {
    padding: "40px 0",
    boxShadow: "1px 1px 9px 2px #0000001a",
    borderRadius: 7,
  },
  cardContentWrapper: {
    padding: "16px 26px",
  },
  downloadBtn: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: Colors.brand.primary,
    color: Colors.white,
    border: `1px solid ${Colors.brand.primary}`,
    textTransform: "uppercase",
    fontWeight: 600,
    height: 45,
    minWidth: 45,
    borderRadius: "100%",
    boxShadow: "2px 2px 8px 1px #0000002b",
    marginLeft: "auto",
    "&:hover": {
      color: Colors.brand.primary,
      background: Colors.white,
      boxShadow: "none",
    },
  },
  title: {
    color: "rgba(0, 0, 0, 0.54)",
    padding: 0,
    fontSize: "1rem",
    fontWeight: 400,
    lineHeight: 1,
    letterSpacing: "0.00938em",
    transform: "translate(0, 1.5px) scale(0.75)",
    transformOrigin: "top left",
  },
}));

const getColumns = (isBaseUser) => {
  const columns = [
    {
      key: "id",
      field: "id",
      headerName: "ID",
      sortable: false,
    },
    {
      key: "title",
      field: "title",
      headerName: "Titre",
      sortable: false,
      renderCell: (param) => {
        return <div>{`${param.row?.title}`}</div>;
      },
    },
    {
      key: "date",
      field: "date",
      headerName: "Date",
      sortable: false,
      renderCell: (param) => {
        return <div>{`${param.row?.date}`}</div>;
      },
    },
    {
      key: "type",
      field: "type",
      headerName: "Type",
      sortable: false,
      renderCell: (param) => {
        return <div>{`${param.row?.activity_type.name}`}</div>;
      },
    },
    {
      key: "time",
      field: "time",
      headerName: "Plage horaire",
      sortable: false,
      renderCell: (param) => {
        const startTime = moment(param.row?.start_time, "h:m:s.ms").format(
          TIME_FORMAT1
        );
        const endTime = moment(param.row?.end_time, "h:m:s.ms").format(
          TIME_FORMAT1
        );
        return <div>{`${startTime} - ${endTime}`}</div>;
      },
    },
    {
      key: "scenario",
      field: "scenario",
      headerName: "Mode",
      sortable: false,
      renderCell: (param) => {
        return <div>{param.row?.activity_scenario?.name}</div>;
      },
    },
    {
      key: "servicepoint",
      field: "servicepoint",
      headerName: "Lieu",
      sortable: false,
      renderCell: (param) => {
        return (
          <div>
            {param.row?.service_point?.name || param.row?.youth_center?.Name}
          </div>
        );
      },
    },
    {
      key: "themes",
      field: "themes",
      headerName: "Thèmes",
      sortable: false,
      renderCell: (param) => {
        return (
          <div>{param.row?.themes.map((theme) => theme.name).join(", ")}</div>
        );
      },
    },
    {
      key: "goals",
      field: "goals",
      headerName: "Objectifs",
      sortable: false,
      renderCell: (param) => {
        return (
          <div>{param.row?.goals.map((goal) => goal.name).join(", ")}</div>
        );
      },
    },
    {
      key: "tags",
      field: "tags",
      headerName: "Étiquettes",
      sortable: false,
      renderCell: (param) => {
        return (
          <div>
            {param.row?.activity_tags?.map((tag) => tag.name).join(", ")}
          </div>
        );
      },
    },
  ];

  if (isBaseUser) {
    return columns.filter((col) => col.headerName !== "Lieu");
  }

  return columns;
};

const exportActivities = async ({
  dateRange,
  title,
  selectedTypes,
  selectedThemes,
  selectedScenarios,
  selectedObjectives,
  selectedTags,
  selectedParticipantType,
  selectedServicePoint,
  observations,
  ageRange,
  isBaseUser,
  selectedRegions,
  activitiesOneYearPrior,
  reportDate,
  activitiesInDateRange,
}) => {
  const selectedFiltersMap = {
    startDate: moment(dateRange.startDate).format("YYYY-MM-DD"),
    endDate: moment(dateRange.endDate).format("YYYY-MM-DD"),
    date: `${dateRange.startDate.format(
      DATE_FORMAT11
    )} - ${dateRange.endDate.format(DATE_FORMAT11)}`,
    title,
    types: selectedTypes.map((t) => t.name).join(", "),
    themes: selectedThemes.map((t) => t.name).join(", "),
    scenarios: selectedScenarios.map((s) => s.name).join(", "),
    goals: selectedObjectives.map((o) => o.name).join(", "),
    tags: selectedTags.map((t) => t.name).join(", "),
    participantType: selectedParticipantType.map((t) => t.name).join(", "),
    selectedServicePoints: selectedServicePoint.map((p) => p.name).join(", "),
    observations,
    ageRange,
  };
  let involvementColumns = [];
  const dp_allParticipantTags = [];
  const dp_allActivityTags = [];
  let dp_pIDsFormationTag = [];
  let dp_pIDsInterventionTag = [];
  let dp_pIDsLibreTag = [];
  let dp_pIDsPlanifiéeTag = [];
  let dp_pIDsRayonnementTag = [];
  let dp_pIDsRéunionduCATag = [];
  let dp_pIDsRéuniondecomitéTag = [];
  let dp_pIDsTravailderueTag = [];
  let dp_pIDsVisibilitéTag = [];

  for (const activity of activitiesInDateRange) {
    const involvementsMap = {};

    for (const involvement of activity.activity_involvements) {
      const mapObj = {
        invKeyId: involvement.id,
        invName: "",
      };

      if (involvement.activity_characteristic) {
        mapObj.invName = involvement.activity_characteristic.name;
      } else if (involvement.activity_tag) {
        mapObj.invName = involvement.activity_tag.name;
      } else if (involvement.activity_theme) {
        mapObj.invName = involvement.activity_theme.name;
      }
      const invNameExists = involvementColumns.some(
        (item) => item.invName === mapObj.invName
      );

      if (!invNameExists) {
        involvementColumns.push(mapObj);
      }
      involvementsMap[involvement.id] = involvement;
    }

    activity.involvementsMap = involvementsMap;
  }

  involvementColumns = involvementColumns.sort((a, b) =>
    a.invName.localeCompare(b.invName)
  );

  let privateFiels = [];

  if (!isBaseUser) {
    privateFiels = [
      "Participants IDs",
      "Participants",
      "Observation",
      "Nombre d’ados additionnels",
      "Étiquettes pour les ados",
      "Nombre de grand public additionnels",
      "Étiquettes pour le grand public",
      "Nombre de présences virtuelles",
      ...involvementColumns.map((inv) => inv.invName),
    ];
  }

  let activityRows = [
    [
      "ID",
      "Date",
      "Title",
      "Type",
      "Mode",
      "Location",
      "Instance régionale RMJQ",
      "Région administrative",
      "Start Time",
      "End Time",
      "Start Date",
      "End Date",
      "Durée",
      "Themes",
      "Objectives",
      "Étiquettes",
      "Catégories",
      "Start Time in seconds",
      "End Time in seconds",
      "Teens 12-17",
      "Participants count",
      "Anim participants count",
      "Ado non-membre",
      "Nombre d'ados additionnels",
      ...privateFiels,
    ],
  ];

  const participantsInvMap = {
    activity_characteristic: {},
    activity_tag: {},
    activity_theme: {},
  };

  const totalAdoPtspMap = {};
  const totalAdoNonMembreMap = {};
  const totalAdoPtspInDateRangeMap = {};
  const totalAdoNonMembreInDateRangeMap = {};
  const allParticipantsMap = {};
  const participantsInDateRangeMap = {};
  const dp_participantTagsInDateRange = [];
  const activityTypeParticipantsMap = {};

  activitiesInDateRange.forEach((row) => {
    if (!activityTypeParticipantsMap[row.activity_type.name]) {
      activityTypeParticipantsMap[row.activity_type.name] = {};
    }

    let observationText = row.observation;
    try {
      const rawObservationsState = JSON.parse(observationText);
      const blocks = rawObservationsState.blocks;
      observationText = blocks
        .map((block) => (!block.text.trim() && "\n") || block.text)
        .join("\n");
    } catch (e) {
      // JSON parsing failed. Observation is already text
    }

    row.start_date = row.start_date.substring(0, row.start_date.indexOf("T"));
    row.end_date = row.end_date.substring(0, row.end_date.indexOf("T"));

    if (Array.isArray(row.activity_involvements)) {
      row.activity_involvements.forEach((invmnt) => {
        let key = null;

        if (invmnt.activity_characteristic) {
          key = "activity_characteristic";
        } else if (invmnt.activity_tag) {
          key = "activity_tag";
        } else if (invmnt.activity_theme) {
          key = "activity_theme";
        }

        if (key) {
          if (!participantsInvMap[key][invmnt[key].name]) {
            participantsInvMap[key][invmnt[key].name] = 0;
          }

          participantsInvMap[key][invmnt[key].name] += invmnt.count;
        }
      });
    }

    moment.duration(
      moment("15:15:00.000", "HH:mm").diff(moment("14:00.000", "HH:mm"))
    );
    const mStartTime = moment(row.start_time, "HH:mm");
    const mEndTime = moment(row.end_time, "HH:mm");
    let mDuration = moment.duration(mEndTime.diff(mStartTime));

    if (row.start_date !== row.end_date) {
      const startDatetime = moment(row.start_date + "T" + row.start_time);
      const endDatetime = moment(row.end_date + "T" + row.end_time);

      mDuration = moment.duration(endDatetime.diff(startDatetime));
    }

    let adoNonMembreCount = 0;
    let animParticipantsCount = 0;
    const rowPids = [];
    let totalAdo12to17Count = 0;

    row.participants.forEach((p) => {
      let isUnique = false;
      const age = getParticipantAge(p, reportDate);

      rowPids.push(p.id);

      if (!participantsInDateRangeMap[p.id]) {
        isUnique = true;
        participantsInDateRangeMap[p.id] = p;
      }

      p.participant_types.forEach((pt) => {
        if (pt.name === "Ado non-membre") {
          if (age >= 12 && age <= 17) {
            totalAdoNonMembreInDateRangeMap[p.id] = p;
            adoNonMembreCount++;
          }
        } else if (pt.name === "Ado") {
          totalAdoPtspInDateRangeMap[p.id] = p;

          if (age >= 12 && age <= 17) {
            activityTypeParticipantsMap[row.activity_type.name][p.id] = p;

            totalAdo12to17Count++;
          }
        } else if (pt.name === "Anim") {
          animParticipantsCount++;
        }

        if (isUnique) {
          dp_participantTagsInDateRange.push(pt.name);
        }
      });
    });

    switch (row.activity_type.name) {
      case "Formation":
        dp_pIDsFormationTag = [...dp_pIDsFormationTag, ...rowPids];
        break;
      case "Intervention":
        dp_pIDsInterventionTag = [...dp_pIDsInterventionTag, ...rowPids];
        break;
      case "Libre":
        dp_pIDsLibreTag = [...dp_pIDsLibreTag, ...rowPids];
        break;
      case "Planifiée":
        dp_pIDsPlanifiéeTag = [...dp_pIDsPlanifiéeTag, ...rowPids];
        break;
      case "Rayonnement":
        dp_pIDsRayonnementTag = [...dp_pIDsRayonnementTag, ...rowPids];
        break;
      case "Réunion du CA":
        dp_pIDsRéunionduCATag = [...dp_pIDsRéunionduCATag, ...rowPids];
        break;
      case "Réunion de comité":
        dp_pIDsRéuniondecomitéTag = [...dp_pIDsRéuniondecomitéTag, ...rowPids];
        break;
      case "Travail de rue":
        dp_pIDsTravailderueTag = [...dp_pIDsTravailderueTag, ...rowPids];
        break;
      case "Visibilité":
        dp_pIDsVisibilitéTag = [...dp_pIDsVisibilitéTag, ...rowPids];
        break;
      default:
        break;
    }

    const actvDate = row.date + "T00:00";
    const sheetRow = [
      parseInt(row.id),
      { t: "d", v: actvDate, z: "yyyy-mm-dd" },
      row.title,
      row.activity_type?.name,
      row.activity_scenario?.name,
      row?.service_point?.name || row?.youth_center?.Name,
      row?.youth_center?.region?.name,
      row?.youth_center?.administrative_region?.name,
      mStartTime.format("HH:mm"),
      mEndTime.format("HH:mm"),
      row.start_date || actvDate,
      row.end_date || actvDate,
      parseFloat(mDuration.asHours().toFixed(2)),
      row.themes.map((t) => t.name).join(", "),
      row.goals.map((g) => g.name).join(", "),
      row.activity_tags
        .map((at) => {
          dp_allActivityTags.push(at.name);

          return at.name;
        })
        .join(", "),
      row.activity_characteristics.map((ac) => ac.name).join(", "),
      mStartTime.diff(moment().startOf("day"), "seconds"),
      mEndTime.diff(moment().startOf("day"), "seconds"),
      totalAdo12to17Count,
      row.participants.length,
      animParticipantsCount,
      adoNonMembreCount,
      row.youth || 0,
    ];

    if (!isBaseUser) {
      sheetRow.push(
        row.participants.reduce(
          (previousValue, currentVal, currentIndex) =>
            `${previousValue}${currentVal.id}${
              currentIndex < row.participants.length - 1 ? `, ` : ``
            }`,
          ""
        ),
        row.participants.reduce(
          (previousValue, currentVal, currentIndex) =>
            `${previousValue}${currentVal.firstName} ${currentVal.lastName}${
              currentIndex < row.participants.length - 1 ? `, ` : ``
            }`,
          ""
        ),
        observationText,
        row.youth,
        row.youth_tags.map((y) => y.name).join(", "),
        row.grand_public || 0,
        row.public_tags.map((p) => p.name).join(", "),
        row.virtual_presences_count,
        ...involvementColumns.map((invMapObj) => {
          if (row.involvementsMap[invMapObj.invKeyId]) {
            return row.involvementsMap[invMapObj.invKeyId].count;
          }

          return "";
        })
      );
    }

    activityRows.push(sheetRow);
  });

  const req = new XMLHttpRequest();
  req.open("GET", xlsxTemplate, true);
  req.responseType = "arraybuffer";

  req.onload = function (e) {
    const data = new Uint8Array(req.response);
    const wb = XLSX.read(data, { type: "array" });

    const filters_ws = wb.Sheets["Filters"];
    filters_ws["!cols"] = [{ wch: 35 }, { wch: 60 }];
    const filters_ws_rows = [
      ["Filtrer par date", selectedFiltersMap.date],
      ["Rechercher par titre", selectedFiltersMap.title],
      ["Filtrer par type", selectedFiltersMap.types],
      ["Filtrer par thème", selectedFiltersMap.themes],
      ["Filtrer par mode", selectedFiltersMap.scenarios],
      ["Filtrer par objectifs", selectedFiltersMap.goals],
      ["Filtrer par étiquettes", selectedFiltersMap.tags],
      ["Rechercher parmi les observations", selectedFiltersMap.observations],
      ["Filtrer par type de participant", selectedFiltersMap.participantType],
      ["Filtrer par lieu", selectedFiltersMap.selectedServicePoints],
      ["Filtrer par âge", selectedFiltersMap.ageRange.join(" - ")],
      ["Raport date", reportDate.format(DATE_FORMAT11)],
    ];

    if (selectedRegions.length) {
      filters_ws_rows.push([
        "Filtrer par instance régionale RMJQ",
        selectedRegions.map((region) => region.name).join(", "),
      ]);
    }

    XLSX.utils.sheet_add_aoa(filters_ws, filters_ws_rows);

    const participants_count_ws = wb.Sheets["Décompte des participants"];
    participants_count_ws["!cols"] = [{ wch: 12 }, { wch: 35 }, { wch: 16 }];
    const pCountData = [["Type", "Value", "Participant Count"]];
    const pCountTypeMapper = {
      activity_characteristic: "Catégorie",
      activity_tag: "Étiquette",
      activity_theme: "Thème",
    };

    Object.keys(participantsInvMap).forEach((pmcKey) => {
      Object.keys(participantsInvMap[pmcKey]).forEach((typeKey) => {
        pCountData.push([
          pCountTypeMapper[pmcKey],
          typeKey,
          participantsInvMap[pmcKey][typeKey],
        ]);
      });
    });
    XLSX.utils.sheet_add_aoa(participants_count_ws, pCountData);

    const participants_ws = wb.Sheets["Participants"];
    participants_ws["!cols"] = [
      { wch: 10 },
      { wch: 20 },
      { wch: 20 },
      { wch: 10 },
      { wch: 12 },
      { wch: 10 },
      { wch: 10 },
      { wch: 10 },
      { wch: 30 },
    ];

    activitiesOneYearPrior.forEach((activity) => {
      activity.participants.forEach((p) => {
        if (!allParticipantsMap[p.id]) {
          p.participant_types.forEach((pt) => {
            dp_allParticipantTags.push(pt.name);
          });
        }

        allParticipantsMap[p.id] = p;
      });
    });

    XLSX.utils.sheet_add_aoa(participants_ws, [
      [
        "Id",
        "Seen in date range",
        "First visit in date range",
        "Gender",
        "Gender other",
        "Birth month",
        "Birth day",
        "Birth year",
        "Types",
        "Handicap",
        "Minorité",
        "Age",
        "Ado 12-17",
      ],
      ...Object.keys(allParticipantsMap).map((pKey) => {
        const p = allParticipantsMap[pKey];
        let first_attendance_date = p.first_attendance_date;
        if (!first_attendance_date) {
          const attendances = _.sortBy(p.attendances, (o) => o.date);
          if (attendances[0]) {
            first_attendance_date = attendances[0].date;
          } else {
            first_attendance_date = "1970-01-01";
          }
        }

        const age = getParticipantAge(p, reportDate);

        if (age >= 12 && age <= 17) {
          const pTagsMap = {};

          p.participant_types.forEach((pt) => {
            pTagsMap[pt.name] = pt;
          });

          if (pTagsMap["Ado"]) {
            p.is12to17 = true;
            totalAdoPtspMap[p.id] = p;
          } else if (pTagsMap["Ado non-membre"]) {
            totalAdoNonMembreMap[p.id] = p;
          }
        }

        return [
          p.id,
          participantsInDateRangeMap[p.id] ? "Yes" : "No",
          moment(first_attendance_date).isBetween(
            dateRange.startDate,
            dateRange.endDate
          )
            ? "Yes"
            : "No",
          p.sexe || "--",
          p.other,
          p.month,
          p.day,
          p.year,
          p.participant_types.map((pt) => pt.name).join(", "),
          p.disabilities.find((d) => d.name === "Handicap") ? "Yes" : "No",
          p.disabilities.find((d) => d.name === "Minorité Visible")
            ? "Yes"
            : "No",
          age ? age : "--",
          p.is12to17 ? "Yes" : "No",
        ];
      }),
    ]);

    const activities_ws = wb.Sheets["Activities"];
    activities_ws["!cols"] = [
      { wch: 8 },
      { wch: 10 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 20 },
      { wch: 10 },
      { wch: 10 },
      { wch: 10 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 10 },
      { wch: 10 },
      { wch: 10 },
      { wch: 10 },
      { wch: 10 },
      { wch: 10 },
      { wch: 10 },
      { wch: 10 },
      { wch: 10 },
      { wch: 10 },
      { wch: 10 },
    ];

    XLSX.utils.sheet_add_aoa(activities_ws, activityRows);

    const dp_allUniqueActivityTags = [...new Set(dp_allActivityTags)];

    dp_pIDsFormationTag = [...new Set(dp_pIDsFormationTag)];
    dp_pIDsInterventionTag = [...new Set(dp_pIDsInterventionTag)];
    dp_pIDsLibreTag = [...new Set(dp_pIDsLibreTag)];
    dp_pIDsPlanifiéeTag = [...new Set(dp_pIDsPlanifiéeTag)];
    dp_pIDsRayonnementTag = [...new Set(dp_pIDsRayonnementTag)];
    dp_pIDsRéunionduCATag = [...new Set(dp_pIDsRéunionduCATag)];
    dp_pIDsRéuniondecomitéTag = [...new Set(dp_pIDsRéuniondecomitéTag)];
    dp_pIDsTravailderueTag = [...new Set(dp_pIDsTravailderueTag)];
    dp_pIDsVisibilitéTag = [...new Set(dp_pIDsVisibilitéTag)];
    const dp_adosInDateRange = [Object.keys(totalAdoPtspInDateRangeMap).length];
    const dp_adosTotal = [Object.keys(totalAdoPtspMap).length];
    const dp_totalPtsInDateRange = [
      Object.keys(participantsInDateRangeMap).length,
    ];
    const dp_adosNonTotal = [Object.keys(totalAdoNonMembreMap).length];
    const dp_adosNonInDateRange = [
      Object.keys(totalAdoNonMembreInDateRangeMap).length,
    ];
    const dp_totalPts = [Object.keys(allParticipantsMap).length];
    const dp_activityTypeUniqueParticipants = Object.keys(
      activityTypeParticipantsMap
    );

    const maxIndex = Math.max(
      dp_allParticipantTags.length,
      dp_allActivityTags.length,
      dp_pIDsFormationTag.length,
      dp_pIDsInterventionTag.length,
      dp_pIDsLibreTag.length,
      dp_pIDsPlanifiéeTag.length,
      dp_pIDsRayonnementTag.length,
      dp_pIDsRéunionduCATag.length,
      dp_pIDsRéuniondecomitéTag.length,
      dp_pIDsTravailderueTag.length,
      dp_pIDsVisibilitéTag.length,
      dp_activityTypeUniqueParticipants.length
    );

    const data_processing_ws_rows = [];

    for (let i = 0; i < maxIndex; i++) {
      const activityType = dp_activityTypeUniqueParticipants[i];
      let activityTypeParticipants = null;

      if (activityType) {
        activityTypeParticipants = Object.keys(
          activityTypeParticipantsMap[activityType]
        ).length;
      }

      data_processing_ws_rows.push([
        dp_allParticipantTags[i],
        dp_allActivityTags[i],
        dp_allUniqueActivityTags[i],
        dp_pIDsFormationTag[i],
        dp_pIDsInterventionTag[i],
        dp_pIDsLibreTag[i],
        dp_pIDsPlanifiéeTag[i],
        dp_pIDsRayonnementTag[i],
        dp_pIDsRéunionduCATag[i],
        dp_pIDsRéuniondecomitéTag[i],
        dp_pIDsTravailderueTag[i],
        dp_pIDsVisibilitéTag[i],
        dp_adosInDateRange[i],
        dp_adosTotal[i],
        dp_totalPtsInDateRange[i],
        dp_adosNonInDateRange[i],
        dp_adosNonTotal[i],
        dp_totalPts[i],
        dp_participantTagsInDateRange[i],
        activityType,
        activityTypeParticipants,
      ]);
    }

    const data_processing_ws = wb.Sheets["Data processing"];
    XLSX.utils.sheet_add_aoa(data_processing_ws, [
      [
        "All participant tags",
        "All activity etiquettes",
        "All unique activity etiquettes",
        "Formation",
        "Intervention",
        "Libre",
        "Planifiée",
        "Rayonnement",
        "Réunion du CA",
        "Réunion de comité",
        "Travail de rue",
        "Visibilité",
        "Ado 12-17 in date range",
        "Ado 12-17 total",
        "Total participants in date range",
        "Ado non-membre 12-17 in date range",
        "Ado non-membre 12-17 total",
        "Total participants",
        "Participant tags in date range",
        "[Activity type - ",
        " - Participants count]",
      ],
      ...data_processing_ws_rows,
    ]);

    XLSX.writeFile(wb, "report.xlsx");
  };

  req.send();
};

const exportActivitiesRMJQuser = async ({ tableData }) => {
  let activityRows = [
    [
      "ID",
      "Date",
      "Titre",
      "Instance régionale RMJQ",
      "Région administrative",
      "Type",
      "Mode",
      "Heure de début",
      "Heure de fin",
      "Date de début",
      "Date de fin",
      "Durée",
      "Thèmes",
      "Objectifs",
      "Étiquettes",
      "Catégories",
    ],
  ];

  tableData.forEach((row) => {
    let observationText = row.observation;
    try {
      const rawObservationsState = JSON.parse(observationText);
      const blocks = rawObservationsState.blocks;
      observationText = blocks
        .map((block) => (!block.text.trim() && "\n") || block.text)
        .join("\n");
    } catch (e) {
      // JSON parsing failed. Observation is already text
    }

    row.start_date = row.start_date.substring(0, row.start_date.indexOf("T"));
    row.end_date = row.end_date.substring(0, row.end_date.indexOf("T"));

    const mStartTime = moment(row.start_time, "HH:mm");
    const mEndTime = moment(row.end_time, "HH:mm");
    let mDuration = moment.duration(mEndTime.diff(mStartTime));

    if (row.start_date !== row.end_date) {
      const startDatetime = moment(row.start_date + "T" + row.start_time);
      const endDatetime = moment(row.end_date + "T" + row.end_time);

      mDuration = moment.duration(endDatetime.diff(startDatetime));
    }

    const actvDate = row.date + "T00:00";

    const sheetRow = [
      parseInt(row.id),
      actvDate,
      row.title,
      row?.youth_center?.region?.name,
      row?.youth_center?.administrative_region?.name,
      row.activity_type?.name,
      row.activity_scenario?.name,
      mStartTime.format("HH:mm"),
      mEndTime.format("HH:mm"),
      row.start_date || actvDate,
      row.end_date || actvDate,
      parseFloat(mDuration.asHours().toFixed(2)),
      row.themes.map((t) => t.name).join(", "),
      row.goals.map((g) => g.name).join(", "),
      row.activity_tags
        .map((at) => {
          return at.name;
        })
        .join(", "),
      row.activity_characteristics.map((ac) => ac.name).join(", "),
    ];

    activityRows.push(sheetRow);
  });

  const req = new XMLHttpRequest();
  req.open("GET", xlsxTemplateRMJQ, true);
  req.responseType = "arraybuffer";

  req.onload = function (e) {
    const data = new Uint8Array(req.response);
    const wb = XLSX.read(data, { type: "array" });

    const activities_ws = wb.Sheets["Activities"];
    XLSX.utils.sheet_add_aoa(activities_ws, activityRows);

    XLSX.writeFile(wb, "report.xlsx");
  };

  req.send();
};

export default function ActivitiesListingView(props) {
  const dispatch = useDispatch();
  const user = useSelector((state) => {
    return state.user;
  });
  const canAccessRegions = [
    USER_PERMISSIONS.ADMINISTRATOR,
    USER_PERMISSIONS.RMJQ,
  ].includes(user.role.name);
  const history = useHistory();
  const classes = useStyles();
  const [tableData, setTableData] = useState(() => []);
  const [tableDataDupe, setTableDataDupe] = useState(() => []);
  const [includeInactive, setIncludeInactive] = useState(false);
  const [ageRange, setAgeRange] = useState(() => [0, 99]);
  const [activityTags, setActivityTags] = useState([]);
  const [globalTags, setGlobalTags] = useState([]);
  const [errorFetchTags, setErrorFetchTags] = useState("");
  const [servicePoints, setServicePoints] = useState([]);
  const [isBaseUser, setIsBaseUser] = useState(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [regions, setRegions] = useState([]);
  const [selectedRegions, setSelectedRegions] = useState([]);
  const [backdropOpen, setBackdropOpen] = useState(false);
  const [reportDate, setReportDate] = useState(() => moment());

  const [calendarAnchorEl, setCalendarAnchorEl] = useState(() => null);
  const handleCalendarOpen = (event) => {
    setCalendarAnchorEl(event.currentTarget);
  };

  const handleCalendarClose = () => {
    setCalendarAnchorEl(null);
  };

  const handleToggle = () => {
    setIncludeInactive((prev) => !prev);
  };

  const [dateRange, setDateRange] = useState(() => ({
    startDate: moment().subtract(1, "month"),
    endDate: moment(),
  }));

  const [title, setTitle] = useState(() => "");
  const [observations, setObservations] = useState(() => "");
  const filter = createFilterOptions();

  const [activityTypes, setActivityTypes] = useState(() => []);
  const { error: errFetchActivityTypes } = useQuery(ACTIVITY_TYPE, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      setActivityTypes(
        data.activityTypes.data.map((at) => {
          return {
            id: at.id,
            ...at.attributes,
          };
        })
      );
    },
  });

  const [activityThemes, setActivityThemes] = useState(() => []);
  const { error: errFetchThemes } = useQuery(THEMES, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      setActivityThemes(
        data.themes.data.map((t) => {
          return {
            id: t.id,
            ...t.attributes,
          };
        })
      );
    },
  });

  const [activityScenarios, setActivityScenarios] = useState(() => []);
  const { error: errFetchActivityScenario } = useQuery(ACTIVITY_SCENARIO, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      setActivityScenarios(
        data.activityScenarios.data.map((as) => {
          return {
            id: as.id,
            ...as.attributes,
          };
        })
      );
    },
  });

  const [activityObjectives, setActivityObjectives] = useState(() => []);
  const { error: errFetchGoals } = useQuery(GOALS, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      setActivityObjectives(
        data.goals.data.map((g) => {
          return {
            id: g.id,
            ...g.attributes,
          };
        })
      );
    },
  });

  const [activityCharacteristics, setActivityCharacteristics] = useState(
    () => []
  );
  const { error: errFetchCharacteristics } = useQuery(
    ACTIVITY_CHARACTERISTICS,
    {
      fetchPolicy: "network-only",
      onCompleted: (data) => {
        setActivityCharacteristics(
          data.activityCharacteristics.data.map((ac) => {
            return {
              id: ac.id,
              ...ac.attributes,
            };
          })
        );
      },
    }
  );

  const [fetchYouthCenter] = useLazyQuery(GET_REGIONS, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      setRegions(
        data.regions.data.map((r) => {
          return {
            id: r.id,
            ...r.attributes,
          };
        })
      );
    },
  });

  const youthCenterName = user.youth_center.Name;

  const { error: errorFetchServicePoints } = useQuery(
    GET_SERVICE_POINT_YOUTH_CENTER(user.youth_center.id),
    {
      fetchPolicy: "network-only",
      onCompleted: (data) => {
        const tmpSP = [
          {
            id: "0",
            name: youthCenterName,
          },
          ...data.youthCenter.data.attributes.service_points.data.map((sp) => {
            return {
              id: sp.id,
              name: sp.attributes.name,
            };
          }),
        ];

        setServicePoints(tmpSP);
      },
    }
  );

  const [allParticpantTypes, setAllParticipantTypes] = useState(() => []);
  const [fetchParticipantTypes, { error: errorFetchParticipantTypes }] =
    useLazyQuery(PARTICIPANT_TYPES, {
      fetchPolicy: "network-only",
      onCompleted: (data) => {
        setAllParticipantTypes(
          data.participantTypes.data.map((pt) => {
            return {
              id: pt.id,
              ...pt.attributes,
            };
          })
        );
      },
    });
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [selectedThemes, setSelectedThemes] = useState([]);
  const [selectedScenarios, setSelectedScenarios] = useState([]);
  const [selectedObjectives, setSelectedObjectives] = useState([]);
  const [selectedServicePoint, setSelectedServicePoint] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedParticipantType, setselectedParticipantType] = useState([]);
  const [loading, setLoading] = useState(() => false);
  const [errFetchActivities, setErrFetchActivities] = useState(() => "");

  const [openErrSnackBar, setOpenErrSnackBar] = useState(() => false);
  const [clientErrMsg, setClientErrMsg] = useState("");

  const snackBarCloseHandler = () => {
    setOpenErrSnackBar(false);
  };

  useQuery(ETIQUETTES_LISTING(user.youth_center.id), {
    onCompleted: (data) => {
      setActivityTags(
        data.activityTags.data.map((t) => {
          return {
            id: t.id,
            ...t.attributes,
          };
        })
      );
    },
    onError: (err) => {
      setErrorFetchTags(String(err));
    },
    fetchPolicy: "network-only",
  });

  useQuery(GLOBAL_ETIQUETTES_LISTING(), {
    onCompleted: (data) => {
      setGlobalTags(
        data.activityTags.data.map((t) => {
          return {
            id: t.id,
            ...t.attributes,
          };
        })
      );
    },
    onError: (err) => {
      setErrorFetchTags(String(err));
    },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (!selectedServicePoint.length) {
      setTableData([...tableDataDupe]);
      return null;
    }

    const selectedServicePointMap = {};

    selectedServicePoint.forEach((point) => {
      selectedServicePointMap[point.id] = point;
    });

    const tableDataCopy = [...tableDataDupe].filter((tbRow) => {
      return selectedServicePointMap[tbRow.service_point?.id || 0];
    });

    setTableData(tableDataCopy);
  }, [selectedServicePoint]);

  const {
    match: { params },
  } = props;

  const marks = [
    {
      value: 0,
      label: "0 ans",
    },
    {
      value: 99,
      label: "99 ans",
    },
  ];

  useEffect(() => {
    fetchActivitiesListing();
  }, [
    dateRange,
    title,
    selectedTypes,
    selectedThemes,
    selectedScenarios,
    selectedObjectives,
    selectedCategories,
    selectedTags,
    selectedParticipantType,
    observations,
    selectedServicePoint,
    page,
    pageSize,
    canAccessRegions,
    selectedRegions,
    ageRange,
    reportDate,
    includeInactive,
  ]);

  const fetchActivitiesListing = async (
    oneYearPrior = false,
    fetchAll = false
  ) => {
    if (!oneYearPrior) setLoading(true);

    try {
      const resBuff = await fetch(
        `${process.env.REACT_APP_BACKEND_URI}/api/activities/find-activities-listing`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${user.jwt}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            startDate: oneYearPrior
              ? moment(dateRange.startDate)
                  .subtract(1, "years")
                  .format("YYYY-MM-DD")
              : moment(dateRange.startDate).format("YYYY-MM-DD"),
            endDate: moment(dateRange.endDate).format("YYYY-MM-DD"),
            title: title,
            activity_types: selectedTypes.map((t) => t.id),
            themes: selectedThemes.map((t) => t.id),
            activity_scenarios: selectedScenarios.map((s) => s.id),
            goals: selectedObjectives.map((o) => o.id),
            activity_characteristics: selectedCategories.map((c) => c.id),
            activity_tags: selectedTags.map((t) => t.id),
            observation: observations,
            participant_types: selectedParticipantType.map((t) => t.id),
            service_point: selectedServicePoint.map((o) => o.id),
            youth_center: user.youth_center.id,
            youth_center_region:
              canAccessRegions && selectedRegions.length
                ? selectedRegions.map((yc) => yc.id)
                : [],
            includeInactiveYouthCenters: includeInactive,
            ageFrom: ageRange[0],
            ageTo: ageRange[1],
            reportDate: reportDate.format("YYYY-MM-DD"),
            pagination: {
              page: fetchAll ? 1 : page + 1,
              pageSize: fetchAll ? null : pageSize,
            },
          }),
        }
      );

      if (resBuff.status !== 200) {
        console.error(await resBuff.json());
        throw new Error("Error fetching activities");
      }

      const res = await resBuff.json();

      if (!oneYearPrior) {
        setTableData(res.results);
        setTableDataDupe(res.results);
        setTotalCount(res.pagination.total);
      }

      return res;
    } catch (e) {
      console.error(e);
      setErrFetchActivities(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (user.role.name === USER_PERMISSIONS.RMJQ) {
      setIsBaseUser(true);
    } else {
      fetchParticipantTypes();
    }

    if (canAccessRegions) {
      fetchYouthCenter();
    }
  }, [user]);

  const [updateUserYouthCenter] = useMutation(UPDATE_USER_YOUTH_CENTER, {
    onCompleted: (data) => {
      window.localStorage.removeItem("location");

      const uptUserPayload = {
        ...user,
        youth_center: {
          id: data.updateUsersPermissionsUser.data.attributes.youth_center.data
            .id,
          ...data.updateUsersPermissionsUser.data.attributes.youth_center.data
            .attendances,
        },
      };

      dispatch({
        type: UPDATE_USER,
        payload: uptUserPayload,
      });
    },
  });

  useEffect(() => {
    let hasError = true;

    if (errFetchActivities) {
      setClientErrMsg("Erreur lors du chargement des activités");
    } else if (errFetchActivityTypes) {
      setClientErrMsg("Erreur lors du chargement des types d'activité");
    } else if (errFetchThemes) {
      setClientErrMsg("Erreur lors du chargement des thèmes");
    } else if (errFetchActivityScenario) {
      setClientErrMsg("Erreur lors du chargement des modes d'activité");
    } else if (errFetchGoals) {
      setClientErrMsg("Erreur lors du chargement des objectifs");
    } else if (errFetchCharacteristics) {
      setClientErrMsg("Erreur lors du chargement des catégories");
    } else if (errorFetchTags) {
      setClientErrMsg("Erreur lors du chargement des étiquettes");
    } else if (errorFetchServicePoints) {
      setClientErrMsg("Erreur lors du chargement des points de service");
    } else if (errorFetchParticipantTypes) {
      setClientErrMsg("Erreur lors du chargement des types de participants");
    } else {
      hasError = false;
    }

    if (hasError) {
      setOpenErrSnackBar(true);
    }
  }, [
    errFetchActivities,
    errFetchActivityTypes,
    errFetchThemes,
    errFetchActivityScenario,
    errFetchGoals,
    errorFetchTags,
    errorFetchServicePoints,
    errorFetchParticipantTypes,
  ]);

  function valuetext(value) {
    return `${value} Years`;
  }

  const allRegionsSelected = regions.length === selectedRegions.length;

  const handleClearRegions = () => {
    setSelectedRegions([]);
  };

  const handleToggleOption = (selectedOptions) => {
    setSelectedRegions(selectedOptions);
  };

  const handleSelectAll = (isSelected) => {
    if (isSelected) {
      setSelectedRegions(regions);
    } else {
      handleClearRegions();
    }
  };

  const handleToggleSelectAll = () => {
    handleSelectAll && handleSelectAll(!allRegionsSelected);
  };

  const handleRegionsChange = (event, selectedOptions, reason) => {
    if (reason === "select-option" || reason === "remove-option") {
      if (selectedOptions.find((option) => option.value === "select-all")) {
        handleToggleSelectAll();
        let result = [];
        result = regions.filter((el) => el.value !== "select-all");
        return setSelectedRegions(result);
      } else {
        handleToggleOption && handleToggleOption(selectedOptions);
        return setSelectedRegions(selectedOptions);
      }
    } else if (reason === "clear") {
      handleClearRegions && handleClearRegions();
    }
  };

  return (
    <div>
      <Header />
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        open={openErrSnackBar}
        onClose={snackBarCloseHandler}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={snackBarCloseHandler}
          severity="error"
        >
          {clientErrMsg}
        </Alert>
      </Snackbar>
      <>
        <div className={classes.root}>
          <Container maxWidth="lg" className={css(AppStyles.marginTop128)}>
            <Card className={classes.cardWrapper}>
              <CardContent className={classes.cardContentWrapper}>
                <Grid
                  style={{ marginTop: "20px", width: "100%" }}
                  container
                  spacing={4}
                  alignItems="center"
                  justifyContent="space-between"
                >
                  {checkPermissions(user?.role?.name, [
                    USER_PERMISSIONS.RMJQ,
                    USER_PERMISSIONS.ADMINISTRATOR,
                    USER_PERMISSIONS.ADMINISTRATOR_LIMITED,
                  ]) && (
                    <Grid
                      item
                      xs={12}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Switch
                        checked={includeInactive}
                        onChange={handleToggle}
                        className={classes.switchBase}
                      />
                      <Typography variant="subtitle1" style={{ marginLeft: 8 }}>
                        Inclure MDJ inactives
                      </Typography>
                    </Grid>
                  )}
                  <Grid item xs={12} md={2}>
                    <Typography
                      variant="h4"
                      className={classes.titleone}
                      style={{ marginRight: 5 }}
                    >
                      Activités
                    </Typography>
                  </Grid>
                  <Grid item xs={1} md={4} alignItems="flex-end">
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="flex-end"
                    >
                      <MuiPickersUtilsProvider
                        locale={frLocale}
                        utils={DateFnsUtils}
                      >
                        <KeyboardDatePicker
                          style={{
                            width: "240px",
                          }}
                          margin="normal"
                          fullWidth
                          disableToolbar
                          variant="inline"
                          format="iiii dd MMMM yyyy"
                          invalidDateMessage=""
                          id="date-timepicker"
                          label="Date du rapport"
                          value={reportDate}
                          onChange={(data) => {
                            setReportDate(moment(data));
                          }}
                        />
                      </MuiPickersUtilsProvider>
                      <Button
                        style={{ marginLeft: 5 }}
                        onClick={async () => {
                          const tableData = (
                            await fetchActivitiesListing(false, true)
                          ).results;

                          if (isBaseUser) {
                            return exportActivitiesRMJQuser({ tableData });
                          }

                          setBackdropOpen(true);

                          const activitiesRes1YearPrior =
                            await fetchActivitiesListing(true, true);
                          const activitiesOneYearPrior =
                            activitiesRes1YearPrior.results;

                          await exportActivities({
                            dateRange,
                            title,
                            selectedTypes,
                            selectedThemes,
                            selectedScenarios,
                            selectedObjectives,
                            selectedTags,
                            selectedParticipantType,
                            selectedServicePoint,
                            observations,
                            ageRange,
                            isBaseUser,
                            selectedRegions,
                            activitiesOneYearPrior,
                            reportDate,
                            activitiesInDateRange: tableData,
                          });

                          setBackdropOpen(false);
                        }}
                        className={classes.downloadBtn}
                      >
                        <GetApp />
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
                <Grid
                  style={{ marginTop: "20px", width: "100%" }}
                  container
                  spacing={4}
                  alignItems="stretch"
                  justifyContent="space-between"
                >
                  <Grid item xs={12} md={4}>
                    <TextField
                      fullWidth={true}
                      onClick={handleCalendarOpen}
                      id="date-picker-inline"
                      label="Filtrer par date"
                      value={`${dateRange.startDate.format(
                        DATE_FORMAT11
                      )} - ${dateRange.endDate.format(DATE_FORMAT11)}`}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <CalendarToday />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <Popover
                      id={calendarAnchorEl ? "calendar-popover" : undefined}
                      open={Boolean(calendarAnchorEl)}
                      anchorEl={calendarAnchorEl}
                      onClose={handleCalendarClose}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                    >
                      <DateRangePicker
                        editableDateInputs={true}
                        retainEndDateOnFirstSelection={true}
                        onChange={(item, a, b) => {
                          setDateRange({
                            startDate: moment(item.selection.startDate),
                            endDate: moment(item.selection.endDate),
                          });
                        }}
                        moveRangeOnFirstSelection={false}
                        ranges={[
                          {
                            startDate: new Date(dateRange.startDate.toString()),
                            endDate: new Date(dateRange.endDate.toString()),
                            key: "selection",
                            color: Colors.brand.primary,
                          },
                        ]}
                        locale={fr}
                      />
                    </Popover>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      id="input-with-icon-grid"
                      label="Rechercher par titre"
                      placeholder="Le titre contient..."
                      fullWidth
                      value={title}
                      onChange={(e) => {
                        setTitle(e.target.value);
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Search />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Autocomplete
                      disableCloseOnSelect
                      multiple
                      id="types-tag"
                      options={
                        activityTypes
                          ? getSortedOptions(activityTypes, "name")
                          : []
                      }
                      getOptionLabel={(option) => option.name}
                      getOptionSelected={(option, value) =>
                        option.name === value.name
                      }
                      value={selectedTypes || []}
                      onChange={(event, newVal) => setSelectedTypes(newVal)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          label="Filtrer par type"
                          placeholder="Rechercher"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Autocomplete
                      disableCloseOnSelect
                      multiple
                      id="themes-tag"
                      options={
                        activityThemes
                          ? getSortedOptions(activityThemes, "name")
                          : []
                      }
                      getOptionLabel={(option) => option.name}
                      getOptionSelected={(option, value) =>
                        option.name === value.name
                      }
                      value={selectedThemes || []}
                      onChange={(event, newVal) => setSelectedThemes(newVal)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          label="Filtrer par thème"
                          placeholder="Rechercher"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Autocomplete
                      disableCloseOnSelect
                      multiple
                      id="scenarios-tag"
                      options={
                        activityScenarios
                          ? getSortedOptions(activityScenarios, "name")
                          : []
                      }
                      getOptionLabel={(option) => option.name}
                      getOptionSelected={(option, value) =>
                        option.name === value.name
                      }
                      value={selectedScenarios || []}
                      onChange={(event, newVal) => setSelectedScenarios(newVal)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          label="Filtrer par mode"
                          placeholder="Rechercher"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Autocomplete
                      disableCloseOnSelect
                      multiple
                      id="objectives-tag"
                      options={
                        activityObjectives
                          ? getSortedOptions(activityObjectives, "name")
                          : []
                      }
                      getOptionLabel={(option) => option.name}
                      getOptionSelected={(option, value) =>
                        option.name === value.name
                      }
                      value={selectedObjectives || []}
                      onChange={(event, newVal) =>
                        setSelectedObjectives(newVal)
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          label="Filtrer par objectifs"
                          placeholder="Rechercher"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Autocomplete
                      disableCloseOnSelect
                      multiple
                      id="tags-tag"
                      options={
                        activityTags
                          ? [
                              ...getSortedOptions(activityTags, "name"),
                              ...getSortedOptions(globalTags, "name"),
                            ]
                          : []
                      }
                      getOptionLabel={(option) => option.name}
                      getOptionSelected={(option, value) =>
                        option.name === value.name
                      }
                      value={selectedTags || []}
                      onChange={(event, newVal) => setSelectedTags(newVal)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          label="Filtrer par étiquettes"
                          placeholder="Rechercher"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Autocomplete
                      disableCloseOnSelect
                      multiple
                      id="activity-categories"
                      options={
                        activityCharacteristics
                          ? getSortedOptions(activityCharacteristics, "name")
                          : []
                      }
                      getOptionLabel={(option) => option.name}
                      getOptionSelected={(option, value) =>
                        option.name === value.name
                      }
                      value={selectedCategories || []}
                      onChange={(event, newVal) =>
                        setSelectedCategories(newVal)
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          label="Filtrer par catégories"
                          placeholder="Rechercher"
                        />
                      )}
                    />
                  </Grid>

                  {!user?.role.name ===
                    USER_PERMISSIONS.ADMINISTRATOR_LIMITED && (
                    <Grid item xs={12} md={4}>
                      <TextField
                        id="input-with-icon-grid"
                        label="Rechercher parmi les observations"
                        placeholder="Les observations contiennent..."
                        fullWidth
                        value={observations}
                        onChange={(e) => {
                          setObservations(e.target.value);
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Search />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  )}

                  {!isBaseUser && (
                    <Grid item xs={12} md={4}>
                      <Autocomplete
                        disableCloseOnSelect
                        multiple
                        id="participant-type-tag"
                        options={
                          allParticpantTypes
                            ? getSortedOptions(allParticpantTypes, "name")
                            : []
                        }
                        getOptionLabel={(option) => option.name}
                        getOptionSelected={(option, value) =>
                          option.name === value.name
                        }
                        value={selectedParticipantType || []}
                        onChange={(event, newVal) => {
                          setselectedParticipantType(newVal);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            label="Filtrer par type de participant"
                            placeholder="Type de participant..."
                          />
                        )}
                      />
                    </Grid>
                  )}
                  {servicePoints.length > 0 && (
                    <Grid item xs={12} md={4}>
                      <Autocomplete
                        disableCloseOnSelect
                        multiple
                        id="service-point"
                        options={
                          servicePoints
                            ? getSortedOptions(servicePoints, "name")
                            : []
                        }
                        getOptionLabel={(option) => option.name}
                        getOptionSelected={(option, value) =>
                          option.name === value.name
                        }
                        value={selectedServicePoint || []}
                        onChange={(event, newVal) =>
                          setSelectedServicePoint(newVal)
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            label="Filtrer par lieu"
                            placeholder="Rechercher"
                          />
                        )}
                      />
                    </Grid>
                  )}

                  {!isBaseUser && (
                    <Grid item xs={12} md={4}>
                      <Typography
                        id="range-slider"
                        gutterBottom
                        className={classes.title}
                      >
                        Filtrer par âge
                      </Typography>
                      <Slider
                        value={ageRange}
                        onChange={(event, newVal) => {
                          setAgeRange(newVal);
                        }}
                        valueLabelDisplay="auto"
                        aria-labelledby="range-slider"
                        getAriaValueText={valuetext}
                        color="secondary"
                        min={0}
                        max={99}
                        marks={marks}
                        style={{
                          marginBottom: 0,
                        }}
                      />
                    </Grid>
                  )}
                  {canAccessRegions && (
                    <Grid item xs={12} md={4}>
                      <Autocomplete
                        multiple
                        id="regions-filter"
                        options={regions}
                        getOptionLabel={(option) => {
                          return `${option.name}`;
                        }}
                        getOptionSelected={(option, value) =>
                          option.id === value.id
                        }
                        value={selectedRegions || []}
                        onChange={handleRegionsChange}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            label="Filtrer par instance régionale RMJQ"
                            placeholder="Rechercher"
                          />
                        )}
                        disableCloseOnSelect={true}
                        filterOptions={(options, params) => {
                          const filtered = filter(options, params);
                          return [
                            { name: "Toutes les régions", value: "select-all" },
                            ...filtered,
                          ];
                        }}
                        renderOption={(option, props) => {
                          return (
                            <li
                              {...props}
                              className={
                                option.value === "select-all"
                                  ? "region-op-divider"
                                  : ""
                              }
                            >
                              {option.name}
                            </li>
                          );
                        }}
                      />
                    </Grid>
                  )}
                </Grid>
                <div className={css(styles.TableWrpper)}>
                  {loading ? (
                    <div className="progress-container">
                      <CircularProgress color="primary" />
                    </div>
                  ) : (
                    <DataGrid
                      className={
                        user?.role.name ===
                        USER_PERMISSIONS.ADMINISTRATOR_LIMITED
                          ? "disable-cursor-pointer activitiesTable"
                          : "activitiesTable"
                      }
                      localeText={frFR.props.MuiDataGrid.localeText}
                      rows={tableData}
                      columns={getColumns(isBaseUser)}
                      isRowSelectable={false}
                      disableSelectionOnClick={true}
                      disableColumnMenu={true}
                      sortingOrder={["desc", "asc", null]}
                      paginationMode="server"
                      pageSize={pageSize}
                      onPageSizeChange={(newPageSize) =>
                        setPageSize(newPageSize)
                      }
                      page={page}
                      onPageChange={(newPage) => setPage(newPage)}
                      rowsPerPageOptions={[10, 50, 100]}
                      rowCount={totalCount}
                      onRowClick={(param) => {
                        if (
                          user?.role.name ===
                          USER_PERMISSIONS.ADMINISTRATOR_LIMITED
                        ) {
                          return null;
                        }

                        if (!isBaseUser) {
                          if (
                            canAccessRegions &&
                            param.row.youth_center?.id &&
                            param.row.youth_center?.id !== user.youth_center.id
                          ) {
                            updateUserYouthCenter({
                              variables: {
                                id: user.id,
                                youthCenter: param.row.youth_center.id,
                              },
                            }).then(() => {
                              window.open(
                                `${ROUTES.DAY}/${moment(
                                  param.row.date,
                                  "YYYY-MM-DD"
                                ).format("DDMMYYYY")}`,
                                "_blank"
                              );
                            });
                          } else {
                            window.open(
                              `${ROUTES.DAY}/${moment(
                                param.row.date,
                                "YYYY-MM-DD"
                              ).format("DDMMYYYY")}`,
                              "_blank"
                            );
                          }
                        }
                      }}
                    />
                  )}
                </div>
              </CardContent>
            </Card>
          </Container>
        </div>
      </>
      <Backdrop
        style={{
          zIndex: 9999,
        }}
        open={backdropOpen}
      >
        <CircularProgress color="primary" />
      </Backdrop>
    </div>
  );
}
