export const checkPermissions = (role, rolesArray) => {
  let allowed = false;

  rolesArray.forEach((allowedRole) => {
    if (role === allowedRole) {
      allowed = true;
    }
  });

  return allowed;
};
