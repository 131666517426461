import React, { Component, useEffect, useRef, useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Colors } from "../../theme";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Divider,
  useTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  DialogContentText,
  Box,
  IconButton,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Card,
  CardContent,
  Typography,
  Snackbar,
  CircularProgress,
  Collapse,
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import { da, fr } from "date-fns/locale";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import {
  Print,
  ExpandMore,
  ExpandLess,
  FlagOutlined,
  CloudUpload,
  Delete,
  AttachFile,
} from "@material-ui/icons";
import { Alert, Autocomplete } from "@material-ui/lab";
import { css } from "aphrodite";
import { AppStyles } from "../../theme";
import { useMutation, useQuery } from "@apollo/client";
import moment from "moment";
import {
  ACTIVITY_TYPE,
  THEMES,
  GOALS,
  ACTIVITY,
  CREATE_ACTIVITY,
  CREATE_ACTIVITY_TAGS,
  DELETE_ACTIVITY,
  UPDATE_ACTIVITY,
  ACTIVITY_SCENARIO,
  ACTIVITY_CHARACTERISTICS,
  CREATE_PARTICIPANT_NOTE,
  UPDATE_PARTICIPANT_NOTE,
  PARTICIPANT_NOTES_FOR_ACTIVITY,
  DELETE_PARTICIPANT_NOTE,
  GETYOUTHTAGS,
  GETPUBLICTAGS,
  CREATE_YOUTH_TAGS,
  CREATE_PUBLIC_TAGS,
  GET_ACTIVITY_TITLES,
  UPDATE_ACTIVITY_FILES,
  ETIQUETTES_LISTING,
  GLOBAL_ETIQUETTES_LISTING,
} from "../../graphql";
import {
  INVALID_START_DATE,
  INVALID_END_DATE,
  INVALID_TITLE,
  INVALID_ACTIVITY_TYPE,
  INVALID_ACTIVITY_SCENARIO,
  INVALID_TAGS,
  INVALID_PARTICIPANTS,
  INVALID_OBSERVATION,
  INVALID_NOTE_INFO,
  STRAPI_TIME_FORMAT,
  STRAPI_DATE_FORMAT,
  ROUTES,
  INVALID_PUBLIC_COUNT,
  INVALID_YOUTH_COUNT,
  BACKEND_URI,
  FORM_VALIDATION_FAILED,
} from "../../constants";
import styles from "./ActivityFormStyles";
import { ActionButton } from "../../components";
import _, { get } from "lodash";
import Util from "../../services/Util";
import { Close, Add, Remove, LibraryAdd } from "@material-ui/icons";
import { getSortedOptions } from "../../helpers/dataHelpers";
import { useHistory } from "react-router-dom";
import { useStore, useSelector } from "react-redux";
import ActivityInvolvementInput from "./components/ActivityInvolvementInputController";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(2),
  },
  flexClass: { flex: 1 },
  appBarBg: {
    background: Colors.brand.primary,
  },
  inputFieldsWrapper: {
    marginTop: theme.spacing(2),
  },
  hrMargin: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  hrMarginForm: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  hrColor: {
    backgroundColor: "rgba(0, 0, 0, 0.2)",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    fontWeight: 600,
    fontSize: 18,
    textTransform: "uppercase",
  },

  green: {
    color: Colors.brand.primary,
  },
  formControl: {
    minWidth: "100%",
  },
  deleteBtn: {
    background: Colors.brand.primary,
    border: `1px solid ${Colors.brand.primary}`,
    textTransform: "uppercase",
    fontWeight: 400,
    boxShadow: "2px 2px 8px 1px #0000002b",
    "&:hover": {
      color: Colors.brand.primary,
      boxShadow: "none",
    },
  },
  cardWrapper: {
    marginTop: 15,
    padding: "20px 0 0px",
    boxShadow: "1px 1px 5px 0px #00000040",
    borderRadius: 3,
  },
  cardContentWrapper: {
    padding: "16px 26px 0",
  },
  cardContentWrapperTwo: {
    padding: "0px 12px 0",
  },
  activityHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  cardActions: {
    marginTop: 15,
    display: "flex",
    justifyContent: "space-between",
  },
  invSectionTitle: {
    marginTop: 25,
  },
  attachmentRow: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    alignItems: "center",
  },
  attachmentHeading: {
    display: "flex",
    alignItems: "center",
  },
  observationFlag: {
    position: "absolute",
    top: 15,
    right: 0,
  },
}));

const Attachment = (props) => {
  const classes = useStyles();

  const handleDelete = (e) => {
    e.preventDefault();

    if (props.deleteCallback) {
      props.deleteCallback();
    }
  };

  const getPreviewImage = () => {
    if (["image/gif", "image/jpeg", "image/png"].includes(props.mime)) {
      return (
        <span
          style={{
            minWidth: 24,
            textAlign: "center",
          }}
        >
          <img
            src={props.src}
            style={{
              height: 24,
              marginRight: 5,
            }}
          />
        </span>
      );
    }

    return <AttachFile />;
  };

  return (
    <div className={classes.attachmentRow}>
      <div className={classes.attachmentHeading}>
        {getPreviewImage()}
        <a href={props.src} target="_blank">
          {props.name} {props.isNew}
        </a>
      </div>

      <IconButton
        aria-label="delete"
        onClick={handleDelete}
        style={{
          padding: 6,
        }}
      >
        <Delete />
      </IconButton>
    </div>
  );
};

export default function ActivityForm(props) {
  const user = useSelector((state) => {
    return state.user;
  });

  const theme = useTheme();
  const classes = useStyles();
  const history = useHistory();
  const [showModal, setShowModal] = useState(() => false);
  const [singleActivity, setSingleActivity] = useState(() => {});
  const [title, setTitle] = useState(() => "");
  const [activityType, setActivityType] = useState(() => "");
  const [activityScenario, setActivityScenario] = useState(() => "");
  const [startTimeSlot, setStartTime] = useState(() => null);
  const [endTimeSlot, setEndTime] = useState(() => null);
  const [publicCount, setPublicCount] = useState(() => "");
  const [youthCount, setYouthCount] = useState(() => "");
  const [themes, setThemes] = useState(() => []);
  const [tags, setTags] = useState(() => []);
  const [allTags, setAllTags] = useState(() => []);
  const [globalTags, setGlobalTags] = useState(() => []);
  const [youthTags, setYouthTags] = useState(() => []);
  const [allYouthTags, setAllYouthTags] = useState(() => []);
  const [publicTags, setPublicTags] = useState(() => []);
  const [allPublicTags, setAllPublicTags] = useState(() => []);
  const [characteristics, setCharacteristics] = useState(() => []);
  const [goals, setGoals] = useState(() => []);
  const [users, setUsers] = useState(() => []);
  const [observation, setObservation] = useState(() => "");
  const [noteFormValues, setNoteFormValues] = useState(() => []);
  const [filledBy, setFilledBy] = useState(() => "");
  const titleRef = useRef(null);
  const publicCountRef = useRef(null);
  const youthCountRef = useRef(null);
  const activityTypeRef = useRef(null);
  const activityScenarioRef = useRef(null);
  const startTimeRef = useRef(null);
  const endTimeRef = useRef(null);
  const goalsRef = useRef(null);
  const themesRef = useRef(null);
  const tagsRef = useRef(null);
  const youthTagsRef = useRef(null);
  const publicTagsRef = useRef(null);
  const characteristicsRef = useRef(null);
  const usersRef = useRef(null);
  const observationRef = useRef(null);
  const [isValidTitle, setIsValidTitle] = useState(() => true);
  const [isValidActivityType, setIsValidActivityType] = useState(() => true);
  const [isValidActivityScenario, setIsValidActivityScenario] = useState(
    () => true
  );
  const [isValidStartTime, setIsValidStartTime] = useState(() => true);
  const [isValidEndTime, setIsValidEndTime] = useState(() => true);
  const [isValidTags, setIsValidTags] = useState(() => true);
  const [isValidYouthTags, setIsValidYouthTags] = useState(() => true);
  const [isValidPublicTags, setIsValidPublicTags] = useState(() => true);
  const [isValidUsers, setIsValidUsers] = useState(() => true);
  const [isValidObservation, setIsValidObservation] = useState(() => true);
  const [isValidNoteValues, setIsValidNoteValues] = useState(() => true);
  const [showInvolvements, setShowInvolvements] = useState(() => false);

  const [openErrSnackBar, setOpenErrSnackBar] = useState(() => false);
  const [clientErrMsg, setClientErrMsg] = useState("");
  const [invCharacteristicsMap, setInvCharacteristicsMap] = useState({});
  const [invTagsMap, setInvTagsMap] = useState({});
  const [invThemesMap, setInvThemesMap] = useState({});
  const [needsReview, setNeedsReview] = useState(false);
  const [extFiles, setExtFiles] = useState([]);
  const [newFiles, setNewFiles] = useState([]);
  const [activityTitles, setActivityTitles] = useState([]);
  const [startDate, setStartDate] = useState(new Date(props.date));
  const [endDate, setEndDate] = useState(new Date(props.date));

  const [virtualPresencesCount, setVirtualPresencesCount] = useState(0);
  const [showVirtualPresencesCount, setShowVirtualPresencesCount] =
    useState(false);
  const [observationsEditorState, setObservationsEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const snackBarErrClose = () => {
    setOpenErrSnackBar(false);
  };

  const store = useStore();

  const [presentParticipants, setPresentParticipants] = useState([]);

  const presentParticipantsMap = useSelector((state) => {
    return state.activity.presentParticipantsMap;
  });

  useEffect(() => {
    setPresentParticipants(
      Object.keys(presentParticipantsMap).map((pKey) => {
        return presentParticipantsMap[pKey];
      })
    );
  }, [presentParticipantsMap]);

  useEffect(() => {
    setStartDate(new Date(props.date));
    setEndDate(new Date(props.date));
  }, [props.date]);

  //Snackbar State
  const [openSnackBar, setOpenSnackBar] = useState(() => false);
  // Activitycreation Response Message
  const [formSubmitResponse, setFormSubmitResponse] = useState("");

  // **SnackBar Close Handler**
  const snackBarCloseHandler = () => {
    setOpenSnackBar(false);
  };

  // **Modal Open Handler**
  const handlerModalOpen = () => {
    setShowModal(true);
  };

  // **Modal Close Handler**
  const handlerModalClose = () => {
    setShowModal(false);
  };

  let handleChangeParticipantID = (i, val) => {
    let newFormValues = _.cloneDeep(noteFormValues);
    newFormValues[i].participants = val || [];
    setNoteFormValues(newFormValues);
  };

  let handleChangeNote = (i, e) => {
    let newFormValues = [...noteFormValues];
    newFormValues[i].textnote = e.target.value;
    setNoteFormValues(newFormValues);
  };

  let addFormFields = () => {
    setNoteFormValues([
      ...noteFormValues,

      {
        participants: [],
        textnote: "",
        noteID: null,
      },
    ]);
  };

  let removeFormFields = (i) => {
    let newFormValues = [...noteFormValues];
    newFormValues.splice(i, 1);
    setNoteFormValues(newFormValues);
  };

  const [errCreateActivity, setErrCreateActivity] = useState(() => null);
  const [activityLoading, setActivityLoading] = useState(() => false);
  const createActivityReq = async (data) => {
    setActivityLoading(true);

    try {
      const createResBuff = await fetch(
        `${process.env.REACT_APP_BACKEND_URI}/api/activities`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.jwt}`,
          },
          body: JSON.stringify(data),
        }
      );

      const createRes = await createResBuff.json();

      props.pushActivityList(createRes);
      setOpenSnackBar(true);
      setFormSubmitResponse("L'activité est créée");
      setActivityLoading(false);
      clearFormData();
    } catch (err) {
      setErrCreateActivity(err.message);
      setActivityLoading(false);
    }
  };

  // for creating note
  const [
    createNoteReq,
    { data: noteInfo, error: errCreateNote, loading: noteLoading },
  ] = useMutation(CREATE_PARTICIPANT_NOTE, {
    onCompleted: (data) => {
      setOpenSnackBar(true);
      setFormSubmitResponse("La note a été soumise");
    },
  });

  //for updating note
  const [
    updateNoteReq,
    { data: updateNote, error: errUpdateNote, loading: updateNoteLoading },
  ] = useMutation(UPDATE_PARTICIPANT_NOTE, {
    onCompleted: (data) => {
      setOpenSnackBar(true);
      setFormSubmitResponse("La note a été soumise");
    },
  });

  // for creating new activity tags
  const [
    createActivityTagReq,
    {
      data: activityTagInfo,
      error: errUptActTags,
      loading: activityTagLoading,
    },
  ] = useMutation(CREATE_ACTIVITY_TAGS, {
    onCompleted: (data) => {
      let combinedTags = _.unionBy(
        [
          {
            id: data.createActivityTag.data.id,
            name: data.createActivityTag.data.attributes.name,
          },
        ],
        tags,
        "name"
      );

      setTags(combinedTags);
    },
  });

  // for creating new youth tags
  const [
    createYouthTagReq,
    { data: youthtTagInfo, error: errUptYouthTags, loading: youthTagLoading },
  ] = useMutation(CREATE_YOUTH_TAGS, {
    onCompleted: (data) => {
      let combinedTags = _.unionBy(
        [
          {
            id: data.createYouthTag.data.id,
            name: data.createYouthTag.data.attributes.name,
          },
        ],
        youthTags,
        "name"
      );

      setYouthTags(combinedTags);
    },
  });

  // for creating new youth tags
  const [
    createPublicTagReq,
    {
      data: publictTagInfo,
      error: errUptPublicTags,
      loading: publicTagLoading,
    },
  ] = useMutation(CREATE_PUBLIC_TAGS, {
    onCompleted: (data) => {
      let combinedTags = _.unionBy(
        [
          {
            id: data.createPublicTag.data.id,
            name: data.createPublicTag.data.attributes.name,
          },
        ],
        publicTags,
        "name"
      );

      setPublicTags(combinedTags);
    },
  });

  // for deleting activity
  const [deleteActivityReq, { error: errDelActivity }] = useMutation(
    DELETE_ACTIVITY,
    {
      onCompleted: (data) => {
        props.popActivityList(data.deleteActivity.data.id);
        setShowModal(false);
      },
    }
  );

  // for deleting note
  const [deleteNoteReq, { error: errDelActvNote }] = useMutation(
    DELETE_PARTICIPANT_NOTE,
    {
      onCompleted: (data) => {
        let _d = _.cloneDeep(noteFormValues);

        _.remove(_d, (a) => a.noteID == data.deleteParticipantNote.data.id);
        setNoteFormValues(_d);

        setFormSubmitResponse("Note supprimée");
        setOpenSnackBar(true);
      },
    }
  );

  const clearFormData = () => {
    setNeedsReview(false);
    setTitle("");
    setActivityType("");
    setActivityScenario("");
    setStartTime(null);
    setEndTime(null);
    setPublicCount("");
    setYouthCount("");
    setObservation("");
    setThemes([]);
    setTags([]);
    setYouthTags([]);
    setPublicTags([]);
    setCharacteristics([]);
    setGoals([]);
    setUsers([]);
    setFilledBy("");
    setNewFiles([]);
    setObservationsEditorState(EditorState.createEmpty());
  };

  //for updating activity
  const [
    updateActivityReq,
    {
      data: activityUpdate,
      error: activityUpdateError,
      loading: activityUpdateLoading,
    },
  ] = useMutation(UPDATE_ACTIVITY, {
    onCompleted: (data) => {
      setOpenSnackBar(true);
      setFormSubmitResponse("Activité mise à jour");
      setNewFiles([]);
    },
  });

  const [updateActivityFilesReq] = useMutation(UPDATE_ACTIVITY_FILES, {
    onCompleted: (data) => {
      const files =
        data.updateActivity?.data?.attributes?.files?.data.map((f) => {
          return {
            id: f.id,
            ...f.attributes,
          };
        }) || [];

      setExtFiles(files.reverse());
      setOpenSnackBar(true);
      setFormSubmitResponse(
        "Les pièces jointes ont été téléversées avec succès"
      );
      setNewFiles([]);
    },
    onError: (err) => {
      console.error("Error updating attachments", err);
      setClientErrMsg(
        "Un problème est survenu lors du téléversement des pièces jointes"
      );
      setOpenErrSnackBar(true);
    },
  });

  useQuery(GET_ACTIVITY_TITLES(user.youth_center.id), {
    onCompleted: (data) => {
      setActivityTitles([
        ...new Set(data.activities.data.map((a) => a.attributes.title)),
      ]);
    },
  });

  const {
    data,
    loading: loadActivity,
    error: errFetchActivity,
  } = useQuery(ACTIVITY(props.activityID), {
    fetchPolicy: "network-only",
    skip: _.isUndefined(props.activityID) || _.isNil(props.activityID),

    onCompleted: (data) => {
      const activity = Util.serialize_ACTIVITY_res(data.activity.data);

      setSingleActivity({ activity });
    },
  });

  const {
    data: note,
    loading: noteLoad,
    error: noteErr,
    refetch: refetchParticipantNotes,
  } = useQuery(PARTICIPANT_NOTES_FOR_ACTIVITY(props.activityID), {
    fetchPolicy: "network-only",
    skip: _.isUndefined(props.activityID) || _.isNil(props.activityID),
    onCompleted: (data) => {
      setNoteFormValues(
        data.participantNotes.data.map((item) => {
          return {
            noteID: item.id,
            textnote: item.attributes.textnote,
            activity: props.activityID,
            participants: item.attributes.participants.data.map((p) => {
              return {
                id: p.id,
                ...p.attributes,
              };
            }),
            youth_center: user.youth_center.id,
          };
        })
      );
    },
  });

  const [allActivityTypes, setAllActivityTypes] = useState([]);
  const { loading: loadActTypes, error: errFetchActTypes } = useQuery(
    ACTIVITY_TYPE,
    {
      onCompleted: (data) => {
        setAllActivityTypes(
          data.activityTypes.data.map((item) => {
            return {
              id: item.id,
              ...item.attributes,
            };
          })
        );
      },
    }
  );

  const [allActivityScenarios, setAllActivityScenarios] = useState([]);
  const { loading: loadActScenario, error: errFetchActScenario } = useQuery(
    ACTIVITY_SCENARIO,
    {
      onCompleted: (data) => {
        setAllActivityScenarios(
          data.activityScenarios.data.map((item) => {
            return {
              id: item.id,
              ...item.attributes,
            };
          })
        );
      },
    }
  );

  const [activityThemes, setActivityThemes] = useState(() => []);
  const { loading: loadActThemes, error: errFetchActThemes } = useQuery(
    THEMES,
    {
      fetchPolicy: "network-only",
      onCompleted: (data) => {
        setActivityThemes(
          data.themes.data.map((t) => {
            return {
              id: t.id,
              ...t.attributes,
            };
          })
        );
      },
    }
  );

  const [activity_characteristics, set_activity_characteristics] = useState([]);
  const { loading: loadActCharacts, error: errFetchActCharacts } = useQuery(
    ACTIVITY_CHARACTERISTICS,
    {
      onCompleted: (data) => {
        set_activity_characteristics(
          data.activityCharacteristics.data.map((item) => {
            return {
              id: item.id,
              ...item.attributes,
            };
          })
        );
      },
    }
  );

  const [allGoals, setAllGoals] = useState([]);
  const { loading: loadActGoals, error: errFetchActGoals } = useQuery(GOALS, {
    onCompleted: (data) => {
      setAllGoals(
        data.goals.data.map((item) => {
          return {
            id: item.id,
            ...item.attributes,
          };
        })
      );
    },
  });

  const { loading: loadYouthTags, error: errFetchYouthTags } = useQuery(
    GETYOUTHTAGS(user.youth_center.id),
    {
      onCompleted: (data) => {
        const _youthTags = data.youthTags.data.map((item) => {
          return {
            id: item.id,
            ...item.attributes,
          };
        });

        setAllYouthTags(getSortedOptions(_youthTags, "name"));
      },
    }
  );

  const { loading: loadPublicTags, error: errFetchPublicTags } = useQuery(
    GETPUBLICTAGS(user.youth_center.id),
    {
      onCompleted: (data) => {
        const _publicTags = data.publicTags.data.map((item) => {
          return {
            id: item.id,
            ...item.attributes,
          };
        });

        setAllPublicTags(getSortedOptions(_publicTags, "name"));
      },
    }
  );

  // formating time slots value in activity start
  const start_time =
    singleActivity && !_.isNil(singleActivity.activity.start_time)
      ? moment(singleActivity.activity.start_time, STRAPI_TIME_FORMAT)
      : null;
  const end_time =
    singleActivity && !_.isNil(singleActivity.activity.end_time)
      ? moment(singleActivity.activity.end_time, STRAPI_TIME_FORMAT)
      : null;
  // formating time slots value in activity end

  useEffect(() => {
    if (!singleActivity) return;
    setTitle(singleActivity?.activity?.title ?? "");
    setActivityType(
      singleActivity?.activity?.activity_type
        ? {
            name: singleActivity.activity.activity_type.name,
            value: singleActivity.activity.activity_type.id,
            showTags: singleActivity.activity.activity_type.showTags,
          }
        : ""
    );
    setActivityScenario(
      singleActivity?.activity?.activity_scenario
        ? {
            name: singleActivity.activity.activity_scenario.name,
            value: singleActivity.activity.activity_scenario.id,
            enable_virtual_presences_count:
              singleActivity.activity.activity_scenario
                .enable_virtual_presences_count || false,
          }
        : ""
    );

    setStartTime(start_time);
    setEndTime(end_time);
    // setObservation(singleActivity?.activity?.observation ?? "");

    const observationStateFromDB = singleActivity?.activity?.observation ?? "";

    let contentState;

    try {
      const observationRawState = JSON.parse(observationStateFromDB);
      contentState = convertFromRaw(observationRawState);
    } catch (e) {
      // console.error(e);
      // Failled to parse the JSON
      // The observation is treated as text and new state is initialized

      contentState = convertFromRaw({
        blocks: [
          {
            key: "637gr",
            text: observationStateFromDB,
            type: "unstyled",
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {
              "text-align": "left",
            },
          },
        ],
        entityMap: {},
      });
    }

    const editorState = EditorState.createWithContent(contentState);
    setObservationsEditorState(editorState);
    setFilledBy(singleActivity?.activity?.filled_by ?? "");
    setThemes(singleActivity?.activity?.themes);
    setTags(singleActivity?.activity?.activity_tags);
    setYouthTags(singleActivity?.activity?.youth_tags);
    setPublicTags(singleActivity?.activity?.public_tags);
    setYouthCount(singleActivity?.activity?.youth);
    setPublicCount(singleActivity?.activity?.grand_public);
    setCharacteristics(
      singleActivity?.activity?.activity_characteristics ?? []
    );
    setGoals(singleActivity?.activity?.goals);
    setUsers(singleActivity?.activity?.participants);

    setNeedsReview(singleActivity?.activity?.needs_review);
    setExtFiles(singleActivity?.activity?.files || []);
    setVirtualPresencesCount(
      singleActivity?.activity?.virtual_presences_count || 0
    );

    setStartDate(
      singleActivity?.activity?.start_date
        ? new Date(singleActivity?.activity?.start_date)
        : new Date(props.date)
    );
    setEndDate(
      singleActivity?.activity?.end_date
        ? new Date(singleActivity?.activity?.end_date)
        : new Date(props.date)
    );

    if (
      !singleActivity?.activity ||
      !singleActivity?.activity?.activity_involvements?.length
    ) {
      return null;
    }

    const { activity_involvements } = singleActivity.activity;

    const newInvCharacteristictsMap = {};
    const newInvTagsMap = {};
    const newInvThemesMap = {};

    for (const involvement of activity_involvements) {
      if (involvement.activity_characteristic) {
        newInvCharacteristictsMap[involvement.activity_characteristic.id] =
          involvement;
      } else if (involvement.activity_tag) {
        newInvTagsMap[involvement.activity_tag.id] = involvement;
      } else if (involvement.activity_theme) {
        newInvThemesMap[involvement.activity_theme.id] = involvement;
      }
    }

    setInvCharacteristicsMap(newInvCharacteristictsMap);
    setInvTagsMap(newInvTagsMap);
    setInvThemesMap(newInvThemesMap);
  }, [singleActivity]);

  useQuery(ETIQUETTES_LISTING(user.youth_center.id), {
    onCompleted: (data) => {
      setAllTags(
        data.activityTags.data.map((at) => {
          return {
            id: at.id,
            ...at.attributes,
          };
        })
      );
    },
    fetchPolicy: "network-only",
  });

  useQuery(GLOBAL_ETIQUETTES_LISTING(), {
    onCompleted: (data) => {
      setGlobalTags(
        data.activityTags.data.map((at) => {
          return {
            id: at.id,
            ...at.attributes,
          };
        })
      );
    },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    let newTag = _.find(youthTags, (tag) => tag.id < 0);

    if (!_.isNil(newTag)) {
      createYouthTagReq({
        variables: {
          name: newTag.name,
          youth_center: user.youth_center.id,
        },
      });
    }

    setAllYouthTags(_.unionBy(allYouthTags, youthTags, "name"));
  }, [youthTags]);

  useEffect(() => {
    let newTag = _.find(publicTags, (tag) => tag.id < 0);

    if (!_.isNil(newTag)) {
      createPublicTagReq({
        variables: {
          name: newTag.name,
          youth_center: user.youth_center.id,
        },
      });
    }

    setAllPublicTags(_.unionBy(allPublicTags, publicTags, "name"));
  }, [publicTags]);

  useEffect(() => {
    let hasError = true;

    if (errCreateActivity) {
      setClientErrMsg("Erreur lors de la création de l'activité");
    } else if (errFetchActivity) {
      setClientErrMsg("Erreur lors de la chargement de l'activité");
    } else if (errFetchActTypes) {
      setClientErrMsg("Erreur lors du chargement des types d'activité");
    } else if (errFetchActScenario) {
      setClientErrMsg("Erreur lors du chargement des modes d'activité");
    } else if (errFetchActThemes) {
      setClientErrMsg("Erreur lors du chargement des thèmes");
    } else if (errFetchActCharacts) {
      setClientErrMsg(
        "Erreur lors du chargement des caractéristiques d'activité"
      );
    } else if (errFetchActGoals) {
      setClientErrMsg("Erreur lors du chargement des objectifs");
    } else if (errFetchYouthTags) {
      setClientErrMsg("Erreur lors du chargement des étiquettes pour les ados");
    } else if (errFetchPublicTags) {
      setClientErrMsg(
        "Erreur lors du chargement des étiquettes pour le grand public"
      );
    } else if (errCreateNote) {
      setClientErrMsg("Erreur lors de la création de la note");
    } else if (errUpdateNote) {
      setClientErrMsg("Erreur lors de la mise à jour de la note");
    } else if (errUptActTags) {
      setClientErrMsg(
        "Erreur lors de la mise à jour des étiquettes d'activité"
      );
    } else if (activityUpdateError) {
      setClientErrMsg("Erreur lors de la mise à jour de l'activité");
    } else if (errUptYouthTags) {
      setClientErrMsg(
        "Erreur lors de la mise à jour des étiquettes pour les ados"
      );
    } else if (errUptPublicTags) {
      setClientErrMsg(
        "Erreur lors de la mise à jour des étiquettes pour le grand public"
      );
    } else if (errDelActivity) {
      setClientErrMsg("Erreur lors de la suppression de l'activité");
    } else if (errDelActvNote) {
      setClientErrMsg("Erreur lors de la suppression de la note");
    } else {
      hasError = false;
    }

    if (hasError) {
      setOpenErrSnackBar(true);
    }
  }, [
    errCreateActivity,
    errFetchActTypes,
    errFetchActScenario,
    errFetchActThemes,
    errFetchActCharacts,
    errFetchActGoals,
    errFetchYouthTags,
    errFetchPublicTags,
    errCreateNote,
    errUpdateNote,
    errUptActTags,
    activityUpdateError,
    errUptYouthTags,
    errUptPublicTags,
    errDelActivity,
    errDelActvNote,
    errFetchActivity,
  ]);

  useEffect(() => {
    if (!allActivityScenarios) return;

    setActivityScenario(
      _.find(allActivityScenarios, function (type) {
        if (type.name == "Présentiel") {
          return { name: type.name, value: type.id };
        }
      })
    );
  }, [allActivityScenarios]);

  const validateForm = () => {
    let isValid = true;
    // required check
    if (_.isEmpty(title) || !Util.isValidName(title)) {
      titleRef.current.focus();
      setIsValidTitle(false);
      isValid = false;
    }

    if (_.isEmpty(activityType)) {
      activityTypeRef.current.focus();
      setIsValidActivityType(false);
      isValid = false;
    }

    if (_.isEmpty(activityScenario)) {
      activityScenarioRef.current.focus();
      setIsValidActivityScenario(false);
      isValid = false;
    }

    if (_.isNil(startTimeSlot)) {
      startTimeRef.current.focus();
      setIsValidStartTime(false);
      isValid = false;
    }
    if (
      _.isNil(endTimeSlot) ||
      (moment(startDate).isSame(endDate) && endTimeSlot <= startTimeSlot)
    ) {
      endTimeRef.current.focus();
      setIsValidEndTime(false);
      isValid = false;
    }
    if (_.isEmpty(users)) {
      usersRef.current.focus();
      setIsValidUsers(false);
      isValid = false;
    }
    if (!_.isEmpty(observation) && !Util.isValidObservation(observation)) {
      usersRef.current.focus();
      setIsValidObservation(false);
      isValid = false;
    }

    return isValid;
  };

  const uploadFiles = async (upFiles) => {
    const formData = new FormData();

    upFiles.forEach((file) => {
      formData.append("files", file);
    });

    const resBuff = await fetch(
      `${process.env.REACT_APP_BACKEND_URI}/api/upload`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${user.jwt}`,
        },
        body: formData,
      }
    );

    const res = await resBuff.json();

    if (props.activityID) {
      updateActivityFilesReq({
        variables: {
          id: props.activityID,
          files: [...extFiles.map((file) => file.id), ...res.map((f) => f.id)],
        },
      });
    }

    return res;
  };

  const getFilesIds = async () => {
    const files = [...extFiles].map((file) => file.id);

    if (newFiles.length) {
      const uptFiles = await uploadFiles(newFiles);

      uptFiles.forEach((upld) => {
        files.push(upld.id);
      });
    }

    return files;
  };

  const getObservationsEdotorStateAsJSON = () => {
    const contentState = observationsEditorState.getCurrentContent();
    return JSON.stringify(convertToRaw(contentState));
  };

  // *form onSubmit func*
  const createActivityFunc = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      const vars = {
        title: title,
        youth: parseInt(youthCount),
        grand_public: parseInt(publicCount),
        activity_type: activityType.value,
        activity_scenario: activityScenario.id,
        service_point: props?.servicePoint == 0 ? null : props?.servicePoint,
        date: moment(props.date).format(STRAPI_DATE_FORMAT),
        start_time: moment(startTimeSlot).format(STRAPI_TIME_FORMAT),
        end_time: moment(endTimeSlot).format(STRAPI_TIME_FORMAT),
        observation: getObservationsEdotorStateAsJSON(),
        goals: _.map(goals, "id"),
        themes: _.map(themes, "id"),
        activity_tags: _.map(tags, "id"),
        public_tags: _.map(publicTags, "id"),
        youth_tags: _.map(youthTags, "id"),
        activity_characteristics: _.map(characteristics, "id"),
        users: _.map(users, "id"),
        youth_center: user.youth_center.id,
        filled_by: filledBy,
        needs_review: needsReview,
        files: await getFilesIds(),
        start_date: startDate.toISOString(),
        end_date: endDate.toISOString(),
        virtual_presences_count: virtualPresencesCount || 0,
      };

      createActivityReq({
        ...vars,
        participants: vars.users,
      });
    } else {
      setClientErrMsg(FORM_VALIDATION_FAILED);
      setOpenErrSnackBar(true);
    }
  };

  // *form delete func*
  const deleteActivityFunc = (e) => {
    e.preventDefault();
    deleteActivityReq({
      variables: {
        id: props.activityID,
      },
    });
  };

  // *form update func*
  const updateActivityFunc = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      const varsUpdate = {
        id: props.activityID,
        title: title,
        youth: parseInt(youthCount),
        grand_public: parseInt(publicCount),
        activity_type: activityType.value,
        activity_scenario: activityScenario.id,
        service_point:
          props?.servicePoint?.value == 0 ? null : props?.servicePoint?.value,
        date: moment(props.date).format(STRAPI_DATE_FORMAT),
        start_time: moment(startTimeSlot).format(STRAPI_TIME_FORMAT),
        end_time: moment(endTimeSlot).format(STRAPI_TIME_FORMAT),
        observation: getObservationsEdotorStateAsJSON(),
        goals: _.map(goals, "id"),
        themes: _.map(themes, "id"),
        activity_tags: _.map(tags, "id"),
        public_tags: _.map(publicTags, "id"),
        youth_tags: _.map(youthTags, "id"),
        activity_characteristics: _.map(characteristics, "id"),
        // activity_characteristics: characteristics,
        users: _.map(users, "id"),
        youth_center: user.youth_center.id,
        filled_by: filledBy,
        needs_review: needsReview,
        files: await getFilesIds(),
        start_date: startDate.toISOString(),
        end_date: endDate.toISOString(),
        virtual_presences_count: virtualPresencesCount || 0,
      };

      const resBuff = await fetch(
        `${BACKEND_URI}/api/activities/update-involvements`,
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + user.jwt,
            "Content-Type": "application/json; charset=utf-8",
          },
          body: JSON.stringify({
            activityId: props.activityID,
            invCharacteristicsMap,
            invTagsMap,
            invThemesMap,
          }),
        }
      );

      await fetch(`${BACKEND_URI}/api/activities/update-tags-relations`, {
        method: "POST",
        headers: {
          Authorization: "Bearer " + user.jwt,
          "Content-Type": "application/json; charset=utf-8",
        },
        body: JSON.stringify({
          youth_center: user.youth_center.id,
          public_tags: _.map(publicTags, "id"),
          youth_tags: _.map(youthTags, "id"),
        }),
      });

      if (resBuff.status === 200) {
        updateActivityReq({
          variables: {
            ...varsUpdate,
          },
        });
      } else {
        const error = await resBuff.text();
        console.error("Error updating involvements", error);
        setClientErrMsg("Erreur lors de la mise à jour de l'activité");
        setOpenErrSnackBar(true);
      }
    } else {
      setClientErrMsg(FORM_VALIDATION_FAILED);
      setOpenErrSnackBar(true);
    }
  };

  const validateNoteForm = () => {
    let isValid = true;
    setIsValidNoteValues(true);
    // required check
    noteFormValues.map((f) => {
      if (_.isEmpty(f.textnote) || _.isEmpty(f.participants)) {
        setIsValidNoteValues(false);
        isValid = false;
      }
    });

    return isValid;
  };

  // *note create func*
  const submitNoteFunc = (e) => {
    e.preventDefault();
    if (validateNoteForm()) {
      let asynNoteFun = async () => {
        let formValues = _.cloneDeep(noteFormValues);

        formValues = await Promise.all(
          formValues.map(async (f) => {
            if (f.noteID) {
              const vars = {
                id: f.noteID,
                textnote: f.textnote,
                activity: props.activityID,
                participantIds: f.participants.map((p) => p.id),
                youth_center: user.youth_center.id,
              };
              updateNoteReq({
                variables: {
                  ...vars,
                },
              });
              return { ...f };
            } else {
              const vars = {
                textnote: f.textnote,
                activity: props.activityID,
                participantIds: f.participants.map((p) => p.id),
                youth_center: user.youth_center.id,
              };
              let created = await createNoteReq({
                variables: {
                  ...vars,
                },
              });
              return {
                ...f,
                noteID:
                  created?.data?.createParticipantNote?.participantNote?.id,
              };
            }
          })
        );

        refetchParticipantNotes().then((result) => {
          const data = result.data;
          setNoteFormValues(
            data.participantNotes.data.map((item) => {
              return {
                noteID: item.id,
                textnote: item.attributes.textnote,
                activity: props.activityID,
                participants: item.attributes.participants.data.map((p) => {
                  return {
                    id: p.id,
                    ...p.attributes,
                  };
                }),
                youth_center: user.youth_center.id,
              };
            })
          );
        });
      };
      asynNoteFun();
    }
  };

  // *note delete func*
  const deleteNoteFunc = (noteId) => {
    deleteNoteReq({
      variables: {
        id: noteId,
      },
    });
  };

  if (
    loadActTypes ||
    loadActScenario ||
    loadActThemes ||
    loadActCharacts ||
    loadActGoals ||
    loadYouthTags ||
    loadPublicTags
  ) {
    return (
      <div className="progress-container">
        <CircularProgress color="primary" />
      </div>
    );
  }

  const handleInvCount = ({ newCount, id, type }) => {
    switch (type) {
      case "characteristic":
        setInvCharacteristicsMap({
          ...invCharacteristicsMap,
          [id]: {
            ...invCharacteristicsMap[id],
            count: newCount,
          },
        });
        break;
      case "tag": {
        setInvTagsMap({
          ...invTagsMap,
          [id]: {
            ...invTagsMap[id],
            count: newCount,
          },
        });
        break;
      }
      case "theme":
        setInvThemesMap({
          ...invThemesMap,
          [id]: {
            ...invThemesMap[id],
            count: newCount,
          },
        });
        break;
      default:
        break;
    }
  };

  const handleExtFileDelete = async (file, index) => {
    const resBuff = await fetch(`${BACKEND_URI}/api/upload/files/${file.id}`, {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + user.jwt,
      },
    });

    if (resBuff.status !== 200) {
      setClientErrMsg("Something when wrong deleting the file.");
      setOpenErrSnackBar(true);
      return null;
    }

    const filesCopy = [...extFiles];
    filesCopy.splice(index, 1);
    setExtFiles(filesCopy);
  };

  const renderCollapseInvolvements = () => {
    if (
      _.isEmpty(invCharacteristicsMap) &&
      _.isEmpty(invTagsMap) &&
      _.isEmpty(invThemesMap)
    ) {
      return <div></div>;
    }

    return (
      <>
        <div className={classes.cardActions}>
          <Typography
            style={{
              color: "rgba(0, 0, 0, 0.54)",
              display: "flex",
              alignItems: "center",
            }}
          >
            Décompte des participants
          </Typography>
          <IconButton
            expand={String(showInvolvements)}
            onClick={() => {
              setShowInvolvements(!showInvolvements);
            }}
          >
            {showInvolvements ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </div>

        <Collapse in={showInvolvements} timeout="auto" unmountOnExit>
          <Card className={classes.cardWrapper} style={{ paddingTop: 0 }}>
            <CardContent className={classes.cardContentWrapperTwo}>
              <div>
                {Boolean(Object.keys(invCharacteristicsMap).length) && (
                  <div>
                    <Typography className={classes.invSectionTitle}>
                      Catégories
                    </Typography>
                    <Grid
                      container
                      spacing={2}
                      className={classes.inputFieldsWrapper}
                    >
                      {Object.keys(invCharacteristicsMap).map((invCharKey) => {
                        const inv = {
                          ...invCharacteristicsMap[invCharKey],
                          type: "characteristic",
                          label:
                            invCharacteristicsMap[invCharKey]
                              .activity_characteristic.name,
                          entityId:
                            invCharacteristicsMap[invCharKey]
                              .activity_characteristic.id,
                        };
                        return (
                          <Grid key={invCharKey} item xs={12} md={6}>
                            <ActivityInvolvementInput
                              {...inv}
                              handleCountUpdate={handleInvCount}
                            />
                          </Grid>
                        );
                      })}
                    </Grid>
                  </div>
                )}
                {Boolean(Object.keys(invTagsMap).length) && (
                  <div>
                    <Typography className={classes.invSectionTitle}>
                      Étiquettes
                    </Typography>
                    <Grid
                      container
                      spacing={2}
                      className={classes.inputFieldsWrapper}
                    >
                      {Object.keys(invTagsMap).map((invTagKey) => {
                        const inv = {
                          ...invTagsMap[invTagKey],
                          type: "tag",
                          label: invTagsMap[invTagKey].activity_tag.name,
                          entityId: invTagsMap[invTagKey].activity_tag.id,
                        };
                        return (
                          <Grid key={invTagKey} item xs={12} md={6}>
                            <ActivityInvolvementInput
                              {...inv}
                              handleCountUpdate={handleInvCount}
                            />
                          </Grid>
                        );
                      })}
                    </Grid>
                  </div>
                )}
                {Boolean(Object.keys(invThemesMap).length) && (
                  <div>
                    <Typography className={classes.invSectionTitle}>
                      Thèmes
                    </Typography>
                    <Grid
                      container
                      spacing={2}
                      className={classes.inputFieldsWrapper}
                    >
                      {Object.keys(invThemesMap).map((invThemeKey) => {
                        const inv = {
                          ...invThemesMap[invThemeKey],
                          type: "theme",
                          label: invThemesMap[invThemeKey].activity_theme.name,
                          entityId: invThemesMap[invThemeKey].activity_theme.id,
                        };

                        return (
                          <Grid key={invThemeKey} item xs={12} md={6}>
                            <ActivityInvolvementInput
                              {...inv}
                              handleCountUpdate={handleInvCount}
                            />
                          </Grid>
                        );
                      })}
                    </Grid>
                  </div>
                )}
              </div>
            </CardContent>
          </Card>
        </Collapse>
      </>
    );
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={openErrSnackBar}
        onClose={snackBarErrClose}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={snackBarErrClose}
          severity="error"
        >
          {clientErrMsg}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={openSnackBar}
        autoHideDuration={4000}
        onClose={snackBarCloseHandler}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={snackBarCloseHandler}
          severity="success"
        >
          {formSubmitResponse}
        </Alert>
      </Snackbar>
      <div className={classes.root} id="activity-form">
        {!_.isEmpty(props.activityID) && (
          <>
            <div className={classes.hrMarginForm}>
              <Divider className={classes.hrColor} />
            </div>
            <div className={classes.activityHeader}>
              <Typography className={css(AppStyles.weight7)}>
                Activité
              </Typography>
              <IconButton
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  history.push(`${ROUTES.ACTIVITIES}/${props.activityID}`);
                }}
              >
                <Print />
              </IconButton>
            </div>
          </>
        )}
        <form
          className={classes.root}
          noValidate
          autoComplete="off"
          onSubmit={
            _.isEmpty(props.activityID) ? createActivityFunc : function () {}
          }
        >
          <Grid container spacing={6} justifyContent="space-between">
            <Grid item xs={12} md={6}>
              <Autocomplete
                fullWidth
                options={activityTitles}
                freeSolo
                id="titre"
                key="titre-autocomple"
                value={title}
                onChange={(e, title) => {
                  setTitle(title, setIsValidTitle(true));
                }}
                onBlur={(e) => {
                  if (e.target.value !== title) {
                    setTitle(e.target.value, setIsValidTitle(true));
                  }
                }}
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      label="Titre"
                      value={title}
                      ref={titleRef}
                    />
                  );
                }}
              />

              {!isValidTitle ? (
                <span className={`${css(AppStyles.formError)}`}>
                  {INVALID_TITLE}
                </span>
              ) : (
                ""
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl className={classes.formControl}>
                <InputLabel id="activity-dropdown-label">
                  Type d'activité
                </InputLabel>
                <Select
                  labelId="activity-dropdown-label"
                  id="activity-dropdown-select"
                  value={activityType}
                  renderValue={(val) => {
                    return val.name;
                  }}
                  onChange={(e) => {
                    setActivityType(
                      e.target.value,
                      setIsValidActivityType(true)
                    );
                  }}
                  ref={activityTypeRef}
                >
                  {/* **activity type data** */}
                  {allActivityTypes &&
                    getSortedOptions(allActivityTypes, "name").map((item) => {
                      return (
                        <MenuItem
                          key={item.id}
                          value={{
                            name: item.name,
                            value: item.id,
                            showTags: item.showTags,
                          }}
                        >
                          {item.name}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
              {!isValidActivityType ? (
                <span className={`${css(AppStyles.formError)}`}>
                  {INVALID_ACTIVITY_TYPE}
                </span>
              ) : (
                ""
              )}
            </Grid>
          </Grid>
          <Grid container spacing={6} justifyContent="space-between">
            <Grid item xs={12} md={6}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardTimePicker
                  fullWidth
                  ampm={false}
                  className={css(AppStyles.mTop0)}
                  margin="normal"
                  id="start-time-picker"
                  label="Heure de début"
                  value={startTimeSlot}
                  onChange={(date) => {
                    const changeDateFormat = moment(date, STRAPI_TIME_FORMAT);
                    setStartTime(changeDateFormat, setIsValidStartTime(true));
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "change time",
                    disabled: true,
                  }}
                  inputRef={startTimeRef}
                />
              </MuiPickersUtilsProvider>

              {!isValidStartTime ? (
                <span className={`${css(AppStyles.formError)}`}>
                  {INVALID_START_DATE}
                </span>
              ) : (
                ""
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardTimePicker
                  fullWidth
                  ampm={false}
                  className={css(AppStyles.mTop0)}
                  margin="normal"
                  id="end-time-picker"
                  label="Heure de fin"
                  value={endTimeSlot}
                  onChange={(date) => {
                    const changeDateFormat = moment(date, STRAPI_TIME_FORMAT);
                    setEndTime(changeDateFormat, setIsValidEndTime(true));
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "change time",
                    disabled: true,
                  }}
                  inputRef={endTimeRef}
                />
              </MuiPickersUtilsProvider>
              {!isValidEndTime ? (
                <span className={`${css(AppStyles.formError)}`}>
                  {INVALID_END_DATE}
                </span>
              ) : (
                ""
              )}
            </Grid>
          </Grid>
          <Grid container spacing={6} justifyContent="space-between">
            <Grid item xs={12} md={6}>
              <MuiPickersUtilsProvider locale={fr} utils={DateFnsUtils}>
                <KeyboardDatePicker
                  fullWidth
                  className={css(AppStyles.mTop0)}
                  margin="normal"
                  id="start-time-picker"
                  label="Date de début"
                  value={startDate}
                  format="yyyy-MM-dd"
                  maxDateMessage="La date ne peut être après la date de fin"
                  maxDate={endDate}
                  onChange={(date) => {
                    // const changeDateFormat = moment(date, STRAPI_DATE_FORMAT);
                    // setStartDate(changeDateFormat, setIsValidStartTime(true));
                    setStartDate(date);
                  }}
                />
              </MuiPickersUtilsProvider>

              {!isValidStartTime ? (
                <span className={`${css(AppStyles.formError)}`}>
                  {INVALID_START_DATE}
                </span>
              ) : (
                ""
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  fullWidth
                  ampm={false}
                  className={css(AppStyles.mTop0)}
                  margin="normal"
                  id="end-date-picker"
                  label="Date de fin"
                  value={endDate}
                  format="yyyy-MM-dd"
                  minDate={startDate}
                  minDateMessage="La date ne peut être avant la date de début"
                  onChange={(date) => {
                    // const changeDateFormat = moment(date, STRAPI_DATE_FORMAT);
                    // setEndDate(changeDateFormat);
                    setEndDate(date);
                  }}
                />
              </MuiPickersUtilsProvider>
              {!isValidEndTime ? (
                <span className={`${css(AppStyles.formError)}`}>
                  {INVALID_END_DATE}
                </span>
              ) : (
                ""
              )}
            </Grid>
          </Grid>
          <Grid container spacing={6} className={classes.inputFieldsWrapper}>
            <Grid item xs={12} md={12}>
              <FormControl className={classes.formControl}>
                <InputLabel id="activity-scenario-dropdown-label">
                  Mode
                </InputLabel>
                <Select
                  labelId="activity-scenario-label"
                  id="activity-scenario-select"
                  value={activityScenario}
                  renderValue={(val) => {
                    return val.name;
                  }}
                  onChange={(e) => {
                    if (!e.target.value?.enable_virtual_presences_count) {
                      setVirtualPresencesCount(0);
                    }

                    setActivityScenario(
                      e.target.value,
                      setIsValidActivityScenario(true)
                    );
                  }}
                  ref={activityScenarioRef}
                >
                  {/* **activity scenario data** */}
                  {allActivityScenarios &&
                    getSortedOptions(allActivityScenarios, "name").map(
                      (item) => {
                        return (
                          <MenuItem
                            key={item.id}
                            value={{
                              name: item.name,
                              id: item.id,
                              enable_virtual_presences_count:
                                item.enable_virtual_presences_count,
                            }}
                          >
                            {item.name}
                          </MenuItem>
                        );
                      }
                    )}
                </Select>
              </FormControl>
              {!isValidActivityScenario ? (
                <span className={`${css(AppStyles.formError)}`}>
                  {INVALID_ACTIVITY_SCENARIO}
                </span>
              ) : (
                ""
              )}
            </Grid>

            <Grid item xs={12} md={12}>
              <Autocomplete
                disableCloseOnSelect
                multiple
                id="themes-tag"
                options={
                  activityThemes ? getSortedOptions(activityThemes, "name") : []
                }
                getOptionLabel={(option) => option.name}
                getOptionSelected={(option, value) =>
                  option.name === value.name
                }
                value={themes || []}
                onChange={(event, newVal) => {
                  setThemes(newVal);

                  const newInvThemesMap = {};

                  for (const theme of newVal) {
                    const count = invThemesMap[theme.id]?.count || 0;
                    const id = invThemesMap[theme.id]?.id || null;

                    newInvThemesMap[theme.id] = {
                      id,
                      count,
                      activity_theme: theme,
                    };
                  }

                  setInvThemesMap(newInvThemesMap);
                }}
                ref={themesRef}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Thèmes"
                    placeholder="Rechercher"
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container spacing={6} className={classes.inputFieldsWrapper}>
            <Grid item xs={12} md={12}>
              <Autocomplete
                disableCloseOnSelect
                multiple
                id="goal-tags"
                options={allGoals ? getSortedOptions(allGoals, "name") : []}
                getOptionLabel={(option) => option.name}
                getOptionSelected={(option, value) =>
                  option.name === value.name
                }
                value={goals || []}
                onChange={(event, newVal) => setGoals(newVal)}
                ref={goalsRef}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Objectifs"
                    placeholder="Rechercher"
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container spacing={6} className={classes.inputFieldsWrapper}>
            <Grid item xs={12} md={12}>
              <div
                style={{
                  position: "relative",
                  display: "flex",
                  width: "100%",
                  alignItems: "flex-end",
                }}
              >
                <Autocomplete
                  disableCloseOnSelect
                  multiple
                  fullWidth
                  id="user-tags"
                  options={
                    presentParticipants
                      ? getSortedOptions(presentParticipants, "firstName")
                      : []
                  }
                  getOptionLabel={(option) =>
                    `${option.firstName} ${option.lastName}`
                  }
                  getOptionSelected={(option, value) => option.id === value.id}
                  value={users || []}
                  onChange={(event, newVal) =>
                    setUsers(newVal, setIsValidUsers(true))
                  }
                  ref={usersRef}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Participants"
                      placeholder="Rechercher"
                    />
                  )}
                />
                <div>
                  <IconButton
                    aria-label="Ajouter tous les participants présents"
                    title="Ajouter tous les participants présents"
                    onClick={() => {
                      setUsers(
                        presentParticipants
                          ? getSortedOptions(presentParticipants, "firstName")
                          : []
                      );
                    }}
                    style={{
                      padding: 6,
                      position: "relative",
                      marginBottom: -6,
                    }}
                  >
                    <LibraryAdd />
                  </IconButton>
                </div>
              </div>
              {!isValidUsers ? (
                <span className={`${css(AppStyles.formError)}`}>
                  {INVALID_PARTICIPANTS}
                </span>
              ) : (
                ""
              )}
            </Grid>
            {activityScenario?.enable_virtual_presences_count && (
              <Grid item xs={12} md={12}>
                <TextField
                  id="virtual-presences-count"
                  label="Nombre de présences virtuelles"
                  fullWidth
                  type={"number"}
                  value={virtualPresencesCount}
                  onChange={(e) => {
                    let count = parseInt(e.target.value);

                    if (count < 0) {
                      count = 0;
                    }

                    setVirtualPresencesCount(count);
                  }}
                />
              </Grid>
            )}
          </Grid>
          {activityType?.showTags && (
            <>
              <Grid container spacing={6} justifyContent="space-between">
                <Grid item xs={12} md={6}>
                  <TextField
                    id="ados-compter"
                    label="Nombre d’ados additionnels"
                    fullWidth
                    type={"number"}
                    value={youthCount}
                    onChange={(e) => setYouthCount(e.target.value)}
                    ref={youthCountRef}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Autocomplete
                    freeSolo
                    disableCloseOnSelect
                    multiple
                    id="youth-tags"
                    options={allYouthTags}
                    getOptionLabel={(option) => option.name || option}
                    getOptionSelected={(option, value) => {
                      return (
                        (_.isString(option)
                          ? option.toLowerCase()
                          : option.name.toLowerCase()) ===
                        (_.isString(value)
                          ? value.toLowerCase()
                          : value.name.toLowerCase())
                      );
                    }}
                    value={youthTags || []}
                    onChange={(event, newVal) => {
                      let alteredObj = newVal.map((val) => {
                        // If selected from dropdown
                        if (_.isObject(val)) return val;

                        // If typed and option available
                        let foundFromTagsObj = _.find(
                          allYouthTags,
                          (tag) => tag.name.toLowerCase() === val.toLowerCase()
                        );

                        // If typed and option not available
                        if (foundFromTagsObj) return foundFromTagsObj;

                        let newTag = { id: Math.random() * -1, name: val };
                        return newTag;
                      });
                      setYouthTags(alteredObj, setIsValidYouthTags(true));
                    }}
                    ref={youthTagsRef}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        label="Étiquettes pour les ados"
                        placeholder="Appuyez sur Entrée pour créer l'ados Étiquettes"
                        fullWidth
                      />
                    )}
                  />
                  {!isValidYouthTags ? (
                    <span className={`${css(AppStyles.formError)}`}>
                      {INVALID_TAGS}
                    </span>
                  ) : (
                    ""
                  )}
                </Grid>
              </Grid>
              <Grid container spacing={6} justifyContent="space-between">
                <Grid item xs={12} md={6}>
                  <TextField
                    id="public-tags_count"
                    label="Nombre de grand public additionnels"
                    fullWidth
                    type={"number"}
                    value={publicCount}
                    onChange={(e) => setPublicCount(e.target.value)}
                    ref={publicCountRef}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Autocomplete
                    freeSolo
                    disableCloseOnSelect
                    multiple
                    id="public-tags"
                    options={allPublicTags}
                    getOptionLabel={(option) => option.name || option}
                    getOptionSelected={(option, value) => {
                      return (
                        (_.isString(option)
                          ? option.toLowerCase()
                          : option.name.toLowerCase()) ===
                        (_.isString(value)
                          ? value.toLowerCase()
                          : value.name.toLowerCase())
                      );
                    }}
                    value={publicTags || []}
                    onChange={(event, newVal) => {
                      let alteredObj = newVal.map((val) => {
                        // If selected from dropdown
                        if (_.isObject(val)) return val;

                        // If typed and option available
                        let foundFromTagsObj = _.find(
                          allPublicTags,
                          (tag) => tag.name.toLowerCase() === val.toLowerCase()
                        );

                        // If typed and option not available
                        if (foundFromTagsObj) return foundFromTagsObj;

                        let newTag = { id: Math.random() * -1, name: val };
                        return newTag;
                      });
                      setPublicTags(alteredObj, setIsValidPublicTags(true));
                    }}
                    ref={publicTagsRef}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        label="Étiquettes pour le grand public"
                        placeholder="Appuyez sur Entrée pour créer l'grand public"
                        fullWidth
                      />
                    )}
                  />
                  {!isValidPublicTags ? (
                    <span className={`${css(AppStyles.formError)}`}>
                      {INVALID_TAGS}
                    </span>
                  ) : (
                    ""
                  )}
                </Grid>
              </Grid>
            </>
          )}

          <Grid container spacing={6} className={classes.inputFieldsWrapper}>
            <Grid item xs={12} md={12}>
              <Autocomplete
                // freeSolo
                disableCloseOnSelect
                multiple
                id="activity-tags"
                options={getSortedOptions([...allTags, ...globalTags], "name")}
                getOptionLabel={(option) => option.name || option}
                getOptionSelected={(option, value) => {
                  return (
                    (_.isString(option)
                      ? option.toLowerCase()
                      : option.name.toLowerCase()) ===
                    (_.isString(value)
                      ? value.toLowerCase()
                      : value.name.toLowerCase())
                  );
                }}
                value={tags || []}
                onChange={(event, newVal) => {
                  let alteredObj = newVal.map((val) => {
                    // If selected from dropdown
                    if (_.isObject(val)) return val;

                    // If typed and option available
                    let foundFromTagsObj = _.find(
                      allTags,
                      (tag) => tag.name.toLowerCase() === val.toLowerCase()
                    );

                    // If typed and option not available
                    if (foundFromTagsObj) return foundFromTagsObj;

                    let newTag = { id: Math.random() * -1, name: val };
                    return newTag;
                  });
                  setTags(alteredObj, setIsValidTags(true));

                  const newInvTagsMap = {};

                  for (const tag of newVal) {
                    const count = invTagsMap[tag.id]?.count || 0;
                    const id = invTagsMap[tag.id]?.id || null;

                    newInvTagsMap[tag.id] = {
                      id,
                      count,
                      activity_tag: tag,
                    };
                  }

                  setInvTagsMap(newInvTagsMap);
                }}
                ref={tagsRef}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Étiquettes"
                    placeholder=" "
                    fullWidth
                  />
                )}
              />
              {!isValidTags ? (
                <span className={`${css(AppStyles.formError)}`}>
                  {INVALID_TAGS}
                </span>
              ) : (
                ""
              )}
            </Grid>
          </Grid>

          {activity_characteristics && (
            <Grid container spacing={6} className={classes.inputFieldsWrapper}>
              <Grid item xs={12} md={12}>
                <FormControl
                  component="fieldset"
                  className={classes.formControl}
                >
                  <FormGroup row>
                    {activity_characteristics.map((item, index) => {
                      return (
                        <FormControlLabel
                          key={index}
                          control={
                            <Checkbox
                              ref={characteristicsRef}
                              id={item.id}
                              checked={
                                _.find(characteristics, (i) => i.id == item.id)
                                  ? true
                                  : false
                              }
                              value={item.id}
                              onChange={() => {
                                let data = _.cloneDeep(characteristics);

                                if (_.find(data, (i) => i.id == item.id)) {
                                  data = _.filter(data, (i) => i.id != item.id);
                                } else {
                                  data.push(item);
                                }

                                const newInvCharacteristictsMap = {};

                                for (const characteristic of data) {
                                  const count =
                                    invCharacteristicsMap[characteristic.id]
                                      ?.count || 0;
                                  const id =
                                    invCharacteristicsMap[characteristic.id]
                                      ?.id || null;

                                  newInvCharacteristictsMap[characteristic.id] =
                                    {
                                      id,
                                      count,
                                      activity_characteristic: characteristic,
                                    };
                                }

                                setInvCharacteristicsMap(
                                  newInvCharacteristictsMap
                                );

                                setCharacteristics(data);
                              }}
                            />
                          }
                          label={item.name}
                        />
                      );
                    })}
                  </FormGroup>
                </FormControl>
              </Grid>
            </Grid>
          )}

          {!_.isEmpty(props.activityID) && (
            <>
              <Typography
                className={`${css(AppStyles.mTop25)}`}
                style={{ color: "rgba(0, 0, 0, 0.54)" }}
              >
                Notes sur les participants
              </Typography>
              <Card className={classes.cardWrapper}>
                <CardContent className={classes.cardContentWrapperTwo}>
                  {noteFormValues.map((element, index) => {
                    return (
                      <>
                        <Grid
                          container
                          spacing={3}
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Grid item xs={12} md={12}>
                            <TextField
                              id="remarque"
                              label="Remarque"
                              fullWidth
                              multiline
                              value={element.textnote}
                              onChange={(e) => {
                                handleChangeNote(index, e);
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <Autocomplete
                              multiple
                              id="note-participant-tags"
                              options={
                                presentParticipants
                                  ? getSortedOptions(
                                      presentParticipants,
                                      "firstName"
                                    )
                                  : []
                              }
                              getOptionLabel={(option) => {
                                return `${option.firstName} ${option.lastName}`;
                              }}
                              getOptionSelected={(option, value) =>
                                option.id === value.id
                              }
                              value={element.participants || []}
                              onChange={(event, newValue) =>
                                handleChangeParticipantID(index, newValue)
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="standard"
                                  label="Participants"
                                  placeholder="Rechercher"
                                />
                              )}
                            />

                            {!isValidActivityType ? (
                              <span className={`${css(AppStyles.formError)}`}>
                                {INVALID_ACTIVITY_TYPE}
                              </span>
                            ) : (
                              ""
                            )}
                          </Grid>

                          <Grid item>
                            {element.noteID ? (
                              <Button
                                variant="contained"
                                startIcon={<Remove />}
                                onClick={() => deleteNoteFunc(element.noteID)}
                                className={css(styles.deleteBtn)}
                              >
                                Supprimer
                              </Button>
                            ) : (
                              <Button
                                variant="contained"
                                startIcon={<Remove />}
                                onClick={() => removeFormFields(index)}
                                className={css(styles.deleteBtn)}
                              >
                                Supprimer
                              </Button>
                            )}
                          </Grid>
                          <Grid item></Grid>
                        </Grid>
                        <div className={classes.hrMargin}>
                          <Divider className={classes.hrColor} />
                        </div>
                      </>
                    );
                  })}

                  <Grid
                    container
                    spacing={6}
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Grid item></Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        className={css(
                          styles.updateBtn,
                          AppStyles.mBottom5,
                          AppStyles.mTop15
                        )}
                        startIcon={<Add />}
                        onClick={() => addFormFields()}
                      >
                        Note
                      </Button>
                    </Grid>
                  </Grid>
                  {!_.isEmpty(noteFormValues) && (
                    <div className={classes.hrMargin}>
                      <Divider light={true} />
                    </div>
                  )}

                  {!isValidNoteValues ? (
                    <span className={`${css(AppStyles.formError)}`}>
                      {INVALID_NOTE_INFO}
                    </span>
                  ) : (
                    ""
                  )}
                  {!_.isEmpty(noteFormValues) && (
                    <>
                      <Grid
                        container
                        spacing={4}
                        justifyContent="space-between"
                      >
                        <Grid item></Grid>
                        <Grid item>
                          <ActionButton
                            className={css(styles.addBtn)}
                            title="Sauvegarder les notes"
                            isLoading={_.find(noteFormValues, function (note) {
                              if (note.noteID == null) {
                                return noteLoading;
                              } else {
                                return updateNoteLoading;
                              }
                            })}
                            onClick={submitNoteFunc}
                          />
                        </Grid>
                      </Grid>
                    </>
                  )}
                </CardContent>
              </Card>

              {renderCollapseInvolvements()}
            </>
          )}

          <Grid container spacing={6} className={classes.inputFieldsWrapper}>
            <Grid item xs={12} md={12} style={{ position: "relative" }}>
              <Typography
                style={{
                  color: "rgba(0, 0, 0, 0.54)",
                  marginBottom: 10,
                }}
              >
                Observations
              </Typography>
              <Editor
                editorState={observationsEditorState}
                onEditorStateChange={(editorState) => {
                  setObservationsEditorState(editorState);
                }}
                editorStyle={{
                  border: "1px solid #F1F1F1",
                }}
                toolbar={{
                  options: ["inline", "blockType", "list"],
                  inline: {
                    inDropdown: false,
                    className: undefined,
                    component: undefined,
                    dropdownClassName: undefined,
                    options: ["bold", "italic", "underline"],
                  },
                  blockType: {
                    inDropdown: true,
                    options: ["Normal", "H1", "H2"],
                    className: "normalize-heading",
                    component: undefined,
                    dropdownClassName: undefined,
                  },
                  list: {
                    inDropdown: false,
                    className: undefined,
                    component: undefined,
                    dropdownClassName: undefined,
                    options: ["unordered"],
                  },
                }}
              />
              {!isValidObservation ? (
                <span className={`${css(AppStyles.formError)}`}>
                  {INVALID_OBSERVATION}
                </span>
              ) : (
                ""
              )}
              <FormControlLabel
                className={classes.observationFlag}
                control={
                  <Checkbox
                    title="Besoin de révision"
                    className={`visitor-checkbox`}
                    checked={!!needsReview}
                    icon={<FlagOutlined />}
                    checkedIcon={<FlagOutlined color="error" />}
                    onChange={(e) => {
                      setNeedsReview(e.target.checked);
                    }}
                  />
                }
              />
            </Grid>
          </Grid>

          <Grid container spacing={6} className={classes.inputFieldsWrapper}>
            <Grid item xs={12} md={12}>
              <Button
                variant="contained"
                component="label"
                color="default"
                className={classes.button}
                startIcon={<CloudUpload />}
              >
                Ajouter une pièce jointe
                <input
                  type="file"
                  name="files"
                  onChange={(e) => {
                    const filesMap = e.target.files;
                    const files = Object.keys(filesMap).map(
                      (fileKey) => filesMap[fileKey]
                    );

                    setNewFiles([...newFiles, ...files]);
                    uploadFiles(files);
                  }}
                  hidden
                  multiple
                />
              </Button>

              {newFiles.map((file, index) => {
                return (
                  <Attachment
                    key={index}
                    mime={file.type}
                    name={file.name}
                    src={URL.createObjectURL(file)}
                    deleteCallback={() => {
                      const filesCopy = [...newFiles];
                      filesCopy.splice(index, 1);
                      setNewFiles(filesCopy);
                    }}
                  />
                );
              })}

              {extFiles.map((file, index) => {
                return (
                  <Attachment
                    key={index}
                    mime={file.mime}
                    name={file.name}
                    src={(process.env.REACT_APP_FILES_URI || "") + file.url}
                    deleteCallback={() => {
                      handleExtFileDelete(file, index);
                    }}
                  />
                );
              })}
            </Grid>
          </Grid>

          <Grid container spacing={6} className={classes.inputFieldsWrapper}>
            <Grid item xs={12} md={12}>
              <TextField
                id="standard-multiline-flexible"
                label="Activité saisie par"
                fullWidth
                value={filledBy}
                onChange={(e) => {
                  setFilledBy(e.target.value);
                }}
              />
            </Grid>
          </Grid>

          <Grid
            container
            spacing={6}
            alignItems="center"
            justifyContent="space-between"
            className={classes.inputFieldsWrapper}
          >
            <Grid item>
              {!_.isEmpty(props.activityID) && (
                <ActionButton
                  className={css(styles.deleteBtn)}
                  title="SupprImer"
                  onClick={handlerModalOpen}
                />
              )}

              {/* **Confirmation modal for delete START** */}
              <Dialog
                open={showModal}
                onClose={handlerModalClose}
                aria-labelledby="responsive-dialog-title"
              >
                <DialogTitle id="responsive-dialog-title">
                  Êtes-vous sûr?
                </DialogTitle>
                <Box position="absolute" top={0} right={0}>
                  <IconButton onClick={handlerModalClose}>
                    <Close />
                  </IconButton>
                </Box>
                <DialogContent>
                  <DialogContentText>
                    Voulez-vous vraiment supprimer? Ce processus ne peut pas
                    être annulé.
                  </DialogContentText>
                </DialogContent>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                  style={{ padding: "0 12px 16px 0" }}
                >
                  <Grid item></Grid>
                  <DialogActions>
                    <Grid item xs>
                      <Grid
                        container
                        spacing={2}
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Grid item>
                          <ActionButton
                            title="Annuler"
                            onClick={handlerModalClose}
                            className={css(styles.cancelBtn)}
                          />
                        </Grid>
                        <Grid item>
                          <ActionButton
                            title="Confirmer"
                            onClick={deleteActivityFunc}
                            className={css(styles.deleteBtn)}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </DialogActions>
                </Grid>
              </Dialog>
              {/* **Confirmation modal for delete END** */}
            </Grid>
            <Grid item>
              {!_.isEmpty(props.activityID) ? (
                <ActionButton
                  className={css(styles.updateBtn)}
                  title="Mettre À Jour"
                  onClick={updateActivityFunc}
                  isLoading={activityUpdateLoading}
                />
              ) : (
                <ActionButton
                  className={css(styles.addBtn)}
                  title="Ajouter"
                  type="submit"
                  isLoading={activityLoading}
                />
              )}
            </Grid>
          </Grid>
        </form>
      </div>
    </>
  );
}
