import React, { useEffect, useState } from "react";
import _ from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import { AddUserForm, FindUser, Header, VisitorsList } from "../../components";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  CardContent,
  Container,
  Divider,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { AppStyles } from "../../theme";
import { css } from "aphrodite";
import { ActivityForm } from "../../components";
import { ACTIVITIES, GET_SERVICE_POINT_YOUTH_CENTER } from "../../graphql";
import moment from "moment";
import { useLazyQuery, useQuery } from "@apollo/client";
import { getSortedOptions } from "../../helpers/dataHelpers";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 50,
  },
  appBarBg: {
    background: "#5cbdaf",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hrMargin: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  hrColor: {
    backgroundColor: "rgba(0, 0, 0, 0.2)",
  },
  cardWrapper: {
    marginTop: 15,
    padding: "20px 0 0px",
    boxShadow: "1px 1px 5px 0px #00000040",
    borderRadius: 3,
  },
  cardContentWrapper: {
    padding: "16px 26px 0",
  },
  cardContentWrapperTwo: {
    padding: "0px 26px 0",
  },
  accordion: {
    width: "100%",
    boxShadow: "none",
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
  },
  accordionSummary: {
    backgroundColor: "#f9f9f9",
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
  },
  accordionDetails: {
    display: "flex",
    flexDirection: "column",
  },
  switchBase: {
    color: "#B0BEC5",
    "&.Mui-checked": {
      color: "#66C5B8",
    },
    "&.Mui-checked + .MuiSwitch-track": {
      backgroundColor: "#66C5B8",
    },
  },
  switchTrack: {
    backgroundColor: "#B0BEC5",
  },
}));

export default function ActivitiesView(props) {
  const user = useSelector((state) => {
    return state.user;
  });
  const classes = useStyles();
  const [activityList, setActivityList] = useState(() => []);
  const [servicePoint, setServicePoint] = useState(
    localStorage.getItem("location") || "0"
  );

  const {
    match: { params },
  } = props;

  const date =
    _.isUndefined(params.date) || _.isNil(params.date)
      ? moment()
      : moment(params.date, "DDMMYYYY");

  const { data, refetch: refetchActivities } = useQuery(
    ACTIVITIES(date, user.youth_center.id, servicePoint),
    {
      fetchPolicy: "network-only",
      onCompleted: (data) => {
        setActivityList(
          data.activities.data.map((actv) => {
            return {
              id: actv.id,
              service_point: actv.attributes.service_point.data?.id || 0,
              title: actv.attributes?.title || "",
              date: actv.attributes?.date || "",
              observation: actv.attributes?.observation || "",
            };
          })
        );
      },
    }
  );

  const [forceRefetchActivities] = useLazyQuery(
    ACTIVITIES(date, user.youth_center.id, servicePoint),
    {
      fetchPolicy: "network-only",
      onCompleted: (data) => {
        setActivityList(
          data.activities.data.map((actv) => {
            return {
              id: actv.id,
              service_point: actv.attributes.service_point.data?.id || 0,
              title: actv.attributes?.title || "",
              date: actv.attributes?.date || "",
              observation: actv.attributes?.observation || "",
            };
          })
        );
      },
    }
  );

  const [allServicePoints, setAllServicePoints] = useState([]);
  useQuery(GET_SERVICE_POINT_YOUTH_CENTER(user.youth_center.id), {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      setAllServicePoints(
        data.youthCenter.data.attributes.service_points.data.map((item) => {
          return {
            id: item.id,
            name: item.attributes.name,
            address: item.attributes.address,
          };
        })
      );
    },
  });

  console.log("res", activityList);

  useEffect(() => {
    refetchActivities();
  }, [servicePoint]);

  const particiapntsActivitiesMerged = () => {
    forceRefetchActivities();
  };

  const pushActivityList = (activity) => {
    let arr = _.cloneDeep(activityList);
    arr.push(activity);
    setActivityList(arr);
    forceRefetchActivities();
  };

  const popActivityList = (activityID) => {
    let arr = _.cloneDeep(activityList);
    _.remove(arr, (predicate) => predicate.id === activityID);
    setActivityList(arr);
  };

  const youthCenterName = user.youth_center.Name;

  return (
    <div>
      <Header date={date} />
      <>
        <div className={classes.root}>
          <Container className={css(AppStyles.marginTop128)}>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              {allServicePoints.length > 0 && (
                <div style={{ minWidth: "50%" }}>
                  <Grid item xs={12} md={12}>
                    <InputLabel
                      id="activity-service-dropdown-label"
                      style={{ minWidth: "50%" }}
                    >
                      Lieu
                    </InputLabel>
                    <Select
                      style={{ minWidth: "100%" }}
                      labelId="activity-service-label"
                      id="activity-service-select"
                      value={servicePoint}
                      onChange={(e) => {
                        localStorage.setItem("location", e.target.value);
                        setServicePoint(e.target.value);
                        alert("service point setup");
                      }}
                    >
                      <MenuItem key={0} value={"0"}>
                        {youthCenterName}
                      </MenuItem>
                      {Boolean(allServicePoints.length) &&
                        getSortedOptions(allServicePoints, "name").map(
                          (item) => {
                            return (
                              <MenuItem
                                key={item.id + Math.random()}
                                value={item.id}
                              >
                                {item.name}
                              </MenuItem>
                            );
                          }
                        )}
                    </Select>
                  </Grid>
                </div>
              )}
            </Grid>

            <Grid container spacing={4} style={{ marginTop: 40 }}>
              <Grid item xs={12} md={6}>
                <Typography variant="h6">Présences</Typography>
                <Card className={classes.cardWrapper}>
                  <CardContent className={classes.cardContentWrapper}>
                    <VisitorsList
                      date={date}
                      servicePoint={servicePoint}
                      particiapntsActivitiesMerged={
                        particiapntsActivitiesMerged
                      }
                    />
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography variant="h6">Activités</Typography>
                <Card className={classes.cardWrapper}>
                  <CardContent className={classes.cardContentWrapperTwo}>
                    {/* Activities */}
                    {activityList.map((activity) => (
                      <Accordion
                        key={activity.id}
                        className={classes.accordion}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          className={classes.accordionSummary}
                        >
                          <Typography>{activity.title}</Typography>
                        </AccordionSummary>
                        <AccordionDetails className={classes.accordionDetails}>
                          <ActivityForm
                            date={activity.date}
                            activityID={activity.id}
                            popActivityList={popActivityList}
                          />
                        </AccordionDetails>
                      </Accordion>
                    ))}

                    <Grid
                      container
                      spacing={4}
                      alignItems="flex-end"
                      style={{ marginTop: "16px" }}
                    >
                      <Grid item>
                        <Typography className={css(AppStyles.weight7)}>
                          Ajouter une activité
                        </Typography>
                      </Grid>
                    </Grid>

                    {/* Create Activity Form */}
                    <ActivityForm
                      date={date}
                      servicePoint={servicePoint}
                      pushActivityList={pushActivityList}
                    />
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Container>
        </div>
      </>
    </div>
  );
}